/*
 * Date: 2024
 * Description: Pdf Template
 * Author: Nazim Kadouche @ Skitsc
 */
import React, { useState, useEffect, useRef } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import {
	Container,
	Button,
	Box,
	Typography,
	Table,
	TableContainer,
	Paper,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	ButtonGroup,
} from "@mui/material";

import DocModal from "./modals/doc.modal";

import { f_fetch } from "../../api/fetch";
import {
	i_customer_document,
	i_customer_view_props,
} from "../../interfaces/customer.interface";
import moment from "moment";
import { templates } from "./modals/doc.modal";
import { m_validate_customer_document } from "../../validation/main.middleware";
// Styles pour le PDF
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#ffffff",
		padding: 20,
		display: "flex",
		flexDirection: "column",

		// border: "1px solid black",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
		gap: 10,
		justifyContent: "center",
	},
	title: {
		fontSize: 24,
		marginBottom: 10,
	},
	image: {
		width: 500,
		height: 300,
		// display:'block'
	},
	signatureContainer: {},
});

const CustomerInteractions: React.FC<i_customer_view_props> = (
	props: i_customer_view_props
) => {
	const [documents, setDocuments] = useState<i_customer_document[]>([]);
	const [formError,setFormError] = useState<string>();
	const [fieldError, setFieldError] = useState<string>();
	const [openDocModal, setOpenDocModal] = useState<boolean>(false);
	const [title, setTitle] = useState<string>("");
	const fetch_documents = async () => {
		let res = await f_fetch(
			"/customer/" + props.target._id + "/documents",
			"GET",
			true,
			null
		);
		if (res.type == "Success") {
			console.log(res.data);
			setDocuments(res.data);
		}
	};
	const handleCloseModal = async (data: any) => {
		if (!data || data.target) return setOpenDocModal(false);
		let { title, ...templateData } = data;
		const doc_form = {
			title,
			data: templateData,
			customer_id: props.target._id,
		};
		const [valid, err, field] = m_validate_customer_document(doc_form, "POST");
		if(!valid){
			setFormError(err)
			setFieldError(field)
			return;
		}
		const request: any = await f_fetch(
			"/customer/" + props.target._id + "/documents",
			"POST",
			true,
			doc_form
		);
		if (request.type == "Error") {
			// error handling
			return;
		}
		if (request.type == "Success") {
			setOpenDocModal(false);
			await fetch_documents();
		}
	};
	useEffect(() => {
		fetch_documents();
	}, []);
	return (
		<Container sx={{ width: "100%" }}>
			<Box
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					marginTop: 20,
					marginBottom: 20,
					width: "100%",
				}}
			>
				<Typography>Documents</Typography>
				<Button
					onClick={() => setOpenDocModal(true)}
					disabled={openDocModal}
					variant="contained"
				>
					Send Document
				</Button>
			</Box>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Title</TableCell>
							<TableCell align="right">Signed</TableCell>
							<TableCell align="right">Last Email sent</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{documents.map((row) => (
							<TableRow
								key={row._id}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{templates.find((el) => el.name == row.title)?.label}
								</TableCell>
								<TableCell align="right">
									{row.signing_time
										? moment(row.signing_time * 1000).fromNow()
										: "Not yet"}
								</TableCell>
								<TableCell align="right">
									{row.last_mail_sent
										? moment(row.last_mail_sent * 1000).fromNow()
										: "Not yet"}
								</TableCell>
								<TableCell align="right">
									<ButtonGroup
										variant="outlined"
										aria-label="Basic button group"
									>
										{!row.signing_time && <Button>send</Button>}
										<Button>preview</Button>
										<Button>cancel</Button>
									</ButtonGroup>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<DocModal open={openDocModal} formError={formError} fieldError={fieldError} onClose={handleCloseModal} {...props} />
		</Container>
	);
};

export default CustomerInteractions;
