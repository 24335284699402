/*
 * Date: 2024
 * Description: Component for digital document signature
 * Author: Nazim Kadouche @ Skitsc
 */

import {
	Box,
	Select,
	MenuItem,
	Slider,
	TextField,
	Container,
} from "@mui/material";
import WebFont from "webfontloader";
import { useState, useEffect, useRef } from "react";
interface i_DocumentSignature_props {
	onChange: (canvaData: string) => void;
}

const DocumentSignature = (props: i_DocumentSignature_props) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [current_font, setCurrentFont] = useState<string>("Mr Dafoe");
	const [signature, setSignature] = useState<string>("");
	const [font_size, setFontSize] = useState<number>(30);
	const render_canva = () => {
		const canvas = canvasRef.current;
		if (canvas == null) return;
		const context = canvas.getContext("2d");
		if (!context) return;
		canvas.height = 100;

		context.font = `${font_size}px ${current_font}`;
		context.fillStyle = "black";

		context.fillText(signature, 10, 50);
		const imgData = canvas.toDataURL("image/png");
		props.onChange(imgData);
	};

	const available_fonts = [
		"Mr Dafoe",
		"Monsieur La Doulaise",
		"Fuggles",
		"Ruthie",
		"WindSong",
		"Comforter",
		"Festive",
		"Redacted Script",
		"Cherish",
	];

	useEffect(() => {
		WebFont.load({
			google: {
				families: available_fonts,
			},
			active: render_canva,
		});
	}, []);
	useEffect(render_canva, [signature, current_font, font_size]);
	return (
		<Container maxWidth="sm">
			<Select
				id="font-select"
				value={current_font}
				label="Font"
				fullWidth
				onChange={(event) => setCurrentFont(event.target.value)}
			>
				{available_fonts.map((f) => (
					<MenuItem key={f} value={f}>
						{f}
					</MenuItem>
				))}
			</Select>
			<Slider
				sx={{marginTop:4,marginBottom:4}}
				marks
				max={100}
				min={30}
				step={5}
				value={font_size}
				defaultValue={30}
				onChange={(_, value) =>
					setFontSize(Array.isArray(value) ? value[0] : value)
				}
			/>
			<TextField
				fullWidth
				id="signature"
				label="Signature"
				value={signature}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setSignature(event.target.value);
				}}
			/>
			<Container sx={{width:"100%"}}>

			<canvas ref={canvasRef} height={100} />
			</Container>
		</Container>
	);
};
export default DocumentSignature;
