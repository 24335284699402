/*
 * Date: 2024
 * Description: Claim customer special page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useNavigate } from "react-router-dom"
import { Box , Modal, Typography , Stepper , Step , StepLabel } from "@mui/material"
import { useEffect, useState , useContext, ReactElement } from "react"

//Interfaces && types
import { i_tbl_header,  i_snack_alert , i_alert_props , i_socket_response, i_prompt_modal_props, i_initial_props , i_search_filter ,i_table_v2_props , i_pagination_new, i_file_uploads_props } from "../../interfaces/utility.interface"
import { t_display, t_method } from "../../types/types"

//Utilities
import { empty_promise, default_filter,  default_customer_claim, empty_customer, empty_customer_errors, empty_customer_car, customer_car_errors, empty_car_insurance, empty_car_registration, insurance_errors, registration_errors} from "../../utils/constant"
import { f_fetch } from "../../api/fetch"
import { delay , CheckEnv, f_encode_query_data } from "../../utils/utility"

//Components
import CircularUnderLoad from "../../components/utility/center.loader"
import TblV2 from "../../components/table/table.v2"
import AlertDialog from "../../components/utility/alert"
import ModalPromptBody from "../../components/modal/modal.prompt"
import Footer from "../../components/utility/footer"

//Context
import { MainContext, ThemeContext } from "../../context/context"
import { SocketContext } from "../../context/socket.context"

//Middleware
import { m_validate_customer } from "../../validation/main.middleware"
import { m_force_str, m_validate_email, m_validate_phone, m_validate_str ,  m_validate_opt_zip_code, m_validate_tax_type, m_validate_tax_rates, m_validate_currency, m_validate_payment_condition, m_validate_language, m_validate_zip_code, m_validate_date } from "../../validation/utility.middleware"

//Styles
import { header_row, tbl_boxing } from "../../styles/tbl.styles"
import { i_claim_customer, i_customer, i_customer_car, i_customer_car_errors, i_customer_car_form_props, i_customer_car_insurance_props, i_customer_car_registration_props, i_customer_errors, i_customer_form_props, i_insurance, i_insurance_errors, i_registration, i_registration_errors, t_customer_car_valid_type } from "../../interfaces/customer.interface"
import CustomerClaimInputs from "../../components/settings/claims/customer.claim.inputs"


const ClaimCustomers = ( props : i_initial_props ) : ReactElement => {
    const nav = useNavigate()
    const socket = useContext(SocketContext)
    const { mode } = useContext(ThemeContext)
    const { HandleLogout } = useContext(MainContext)
    const [ step , setStep ] = useState<number>(0)
    const [ loading , setLoading ] = useState<boolean>(true)
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ data , setData ] = useState<i_claim_customer[]>([])
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ count , setCount ] = useState<number>(0)
    const [ filter , setFilter ] = useState<i_search_filter>(default_filter);
    const [ open , setOpen ] = useState<boolean>(false)

    //Existing files
    const [ assets, setAssets ] = useState<string[]>([]);
    const [ assets_reg, setAssetsReg ] = useState<string[]>([]);
    const [ assets_insurances , setAssetsInsurances ] = useState<string[]>([]);
    //Files
    const [ uploads, setUploads ] = useState<any[]>([]);
    const [ uploads_reg , setUploadsReg ] = useState<any[]>([]);
    const [ uploads_insurances , setUploadsInsurances ] = useState<any[]>([]);
    const [ modal_title , setModalTitle ] = useState<string>('')
    const [ modal_loading , setModalLoading ] = useState<boolean>(false)
    const [ target , setTarget ] = useState<i_claim_customer>( default_customer_claim )
    //Inputs
    const [ form_values , setFormValues ] = useState<i_customer>(empty_customer)
    const [ form_car , setFormCar ] = useState<i_customer_car>(empty_customer_car)
    const [ form_car_insurance , setFormCarInsurance ] =  useState<i_insurance>(empty_car_insurance)
    const [ form_car_registration , setFormCarRegistration ] = useState<i_registration>(empty_car_registration)
   
    const [ prev_customer , setPrevCustomer ] = useState<i_customer>(empty_customer);
    //Errors management
    const [ errors_values , setErrorsValues ] = useState<i_customer_errors>(empty_customer_errors)
    const [ errors_car_insurance , setErrorsCarInsurance ] = useState<i_insurance_errors>(insurance_errors)
    const [ errors_car_registration , setErrorsCarRegistration] = useState<i_registration_errors>(registration_errors)
    const [ errors_car , setErrorsCar ] = useState<i_customer_car_errors>(customer_car_errors)

    //Files errors
    const [ Euploads, setEuploads ] = useState<string>("");
    const [ EuploadsReg, setEuploadsReg ] = useState<string>("");
    const [ EuploadsInsurances, setEuploadsInsurances ] = useState<string>("");

    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});

  //Sockets events
  useEffect(() => {
    socket.removeAllListeners("customer_claim");
    socket.on('customer_claim', ( output : i_socket_response ) => {
        console.log('Customer claim received', output)
        if(output.type === 'Update'){
            const updateItem = ( ItemUpdated : i_claim_customer ) => {
                const data_to_update = [ ...data ] 
                const updatedItems = data_to_update.map( ( item : i_claim_customer , i : number) => {
                if (item._id === ItemUpdated._id) {
                    return ItemUpdated
                } else {
                    return item
                }
                })
                setData(updatedItems)
            } 
            updateItem(output.item)
        }
        if(output.type === 'Add'){
            const addRow = ( ItemAdded : i_claim_customer ) => {
                const data_to_update = [ ...data ]
                const objectExists = data_to_update.some(( obj : i_claim_customer ) => obj._id === ItemAdded._id);
                if(!objectExists){
                    data_to_update.push(ItemAdded)
                    setData(data_to_update)    
                }
            } 
            addRow(output.item)
        }
        if(output.type === 'Delete'){
            const DeleteItem = ( ItemDeleted : i_claim_customer ) => {
                const data_to_delete = [ ...data ] 
                const index = data_to_delete.findIndex(( row : any ) => row._id === ItemDeleted._id);
                if(index > -1){
                    data_to_delete.splice(index,1)
                    setData(data_to_delete)
                }
            } 
            DeleteItem(output.item)
        }
    })
  },[socket , data])
    const formatState = ( value : string ) : string => {
        var state = value.toUpperCase()
        var res : string = 'QC'
        if(state.startsWith('Q')) return 'QC'
            else if(state.startsWith('B')) res = 'BC'
            else if(state.startsWith('O')) res = 'ON'
            else if(state.startsWith('M')) res = 'MB'
            else if(state.startsWith('N')) res = 'NB'
            else if(state.startsWith('A')) res = 'AB'
            else if(state.startsWith('L')) res = 'LB'
        return res
    }
    const handleCallbackEdit = ( row : i_claim_customer ) => {
        console.log("The new row : " , row)
        const state = formatState(row.state)
        const licence_state = formatState(row.licence.licence_province)
        const clean_customer  = { ...row , ...row , street : row.address, state : state ,  licence : { ...row.licence, licence_province : licence_state} }
        console.log("Clean customer : " , clean_customer)
        setAssets(row.licence.path)
        setAssetsReg(row.registration.path)
        setFormCarInsurance(row.insurance)
        setFormCarRegistration(row.registration)
        setAssetsInsurances(row.insurance.path)
        handleTarget(clean_customer)
        setDisplay('form')
    }
    const handleCallbackDisable = ( row : i_claim_customer ) => {
        setTarget(row)
        setOpen(true)
        let disabled_title : string = 'Are you sure to disable : '
        if(row.disabled === true) disabled_title = 'Are you sure to enable : '
        setModalTitle(disabled_title + row.first_name + ' ' + row.last_name)
    }
    const fetchDisable = async() => {
        setModalLoading(true)
        await delay(1000)
        const res = await f_fetch('/claim/'+target._id , 'PATCH' , true , null)
        if(res.type !== 'Success'){
            setApiError({open : true , promise : res})
        }
        if(res.type === 'Unauthorized') HandleLogout(nav)
        setModalLoading(false)
        setOpen(false)
    }
    const handleTarget = ( row : i_claim_customer ) => {
        const new_customer = {...empty_customer,...row }
        setFormValues(new_customer)
    }
    const handleSubmitForm = async() => {
        var method : t_method = 'POST'
        if( form_values._id !== '') method = 'PUT'
        const [valid , msg , field] = m_validate_customer(form_values , method)
        if(valid){
            const res = await f_fetch('/customer/claims' , method , true , form_values)
            if(res.type === 'Success'){
                handleTarget(default_customer_claim)
                handleForm()
                setDisplay('table')
            }
            setApiError({open : true, promise : res});
        }else{
            setErrorsValues({...errors_values , [field] : msg})
        }
    }
    const handleForm = () => {
        handleTarget(default_customer_claim)
        setDisplay('form')
        setErrorsValues(empty_customer_errors)
    }
    useEffect(() => {
        const f_fetch_claims = async () => {
            setLoading(true)
            await delay(200)
            const params = f_encode_query_data(filter)
            const res = await f_fetch("/claims/filtered?"+params , 'GET' , true , null)
            if(res.type === "Success") {
                setData(res.data.claims)
                setCount(res.data.count)
            }
            if(res.type === 'Unauthorized') HandleLogout(nav)
            setLoading(false)
        }
        f_fetch_claims()
    },[nav , HandleLogout , filter])
    // useEffect(() => {
    //     const F = form_values
    //     const E = errors_values;
    //     if(E.customer_type !== '' && Number(F.customer_type)) setErrorsValues({ ...E , customer_type : ''})
    //     if(E.title !== '' && Number(F.title)) setErrorsValues({ ...E , title : ''})
    //     if(E.first_name !== '' && m_force_str(F.first_name) === '') setErrorsValues({ ...E , first_name : ''})
    //     if(E.middle_name !== '' && m_validate_str(F.middle_name)) setErrorsValues({ ...E , middle_name : ''})
    //     if(E.last_name !== '' && m_force_str(F.last_name) === '') setErrorsValues({ ...E , last_name : ''})
    //     if(E.company_name !== '' && m_validate_str(F.company_name)) setErrorsValues({ ...E , company_name : ''})
    //     if(E.email !== '' && m_validate_email(F.email)) setErrorsValues({ ...E , email : ''})
    //     if(E.phone_one !== '' && m_validate_phone(F.phone_one)) setErrorsValues({ ...E , phone_one : ''})
    //     if(E.phone_two !== '' && m_validate_phone(F.phone_two)) setErrorsValues({ ...E , phone_two : ''})
    //     if(E.tax_preference !== '' && m_validate_tax_type(F.tax_preference)) setErrorsValues({ ...E , tax_preference : ''})
    //     if(E.tax_rate !== '' && m_validate_tax_rates(F.tax_rate)) setErrorsValues({ ...E , tax_rate : ''})
    //     if(E.currency !== '' && m_validate_currency(F.currency)) setErrorsValues({ ...E , currency : ''})
    //     if(E.payment_terms !== '' && m_validate_payment_condition(F.payment_terms)) setErrorsValues({ ...E , payment_terms : ''})
    //     if(E.portal_language !== '' && m_validate_language(F.portal_language)) setErrorsValues({ ...E , portal_language : ''})
    //     if(E.attendance !== '' && m_force_str(F.attendance) === '') setErrorsValues({ ...E , attendance : ''})
    //     if(E.unit_number !== '' && m_validate_str(F.unit_number)) setErrorsValues({ ...E , unit_number : ''})
    //     if(E.door_number !== '' && m_force_str(F.door_number) === '') setErrorsValues({ ...E , door_number : ''})
    //     if(E.street !== '' && m_force_str(F.street) === '') setErrorsValues({ ...E , street : ''})
    //     if(E.city !== '' && m_force_str(F.city) === '') setErrorsValues({ ...E , city : ''})
    //     if(E.state !== '' && m_force_str(F.state) === '') setErrorsValues({ ...E , state : ''})
    //     if(E.zip !== '' && ( F.complete ? m_validate_zip_code(F.zip) : m_validate_opt_zip_code(F.zip) )) setErrorsValues({ ...E , zip : ''})
    //     if(E.contact_array && E.contact_array.length > 0) setErrorsValues({ ...E , contact_array : ''})
    //     if(E.licence.licence_number !== '' && m_force_str(F.licence.licence_number) === '') setErrorsValues({ ...E , licence  : {  ...E.licence, licence_number :'' }})
    //     if(E.licence.reference_number !== '' && m_force_str(F.licence.reference_number) === '') setErrorsValues({ ...E , licence  : {  ...E.licence, reference_number :'' }})
    //     if(E.licence.expiration_date !== '' && m_validate_date(F.licence.expiration_date)) setErrorsValues({ ...E , licence  : {  ...E.licence, expiration_date :'' }})
    //     if(E.licence.delivery_date !== '' && m_validate_date(F.licence.delivery_date)) setErrorsValues({ ...E , licence  : {  ...E.licence, delivery_date :'' }})
    //     if(E.licence.birth_date !== '' && m_validate_date(F.licence.birth_date)) setErrorsValues({ ...E , licence  : {  ...E.licence, birth_date :'' }})
    //     if(E.notes !== '' && m_validate_str(F.notes)) setErrorsValues({ ...E , notes : ''})
    // },[form_values , errors_values])

    const handleRow = (row : i_claim_customer , type : t_display) => {
        if(type === 'form') handleCallbackEdit(row)
        if(type === 'disable') handleCallbackDisable(row)
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const licence_uploads_props : i_file_uploads_props = {
        uploads : uploads,
        setUploads : setUploads,
        assets : assets,
        setAssets : setAssets,
        Euploads : Euploads,
        setEuploads : setEuploads,
        type : 'form',
        path : '/webapi/uploads/newclaim/',
        title : 'License images ( back and front ) *',
        setApiError : setApiError,
        max : 2
    }
    const registration_uploads_props : i_file_uploads_props = {
        uploads : uploads_reg,
        setUploads : setUploadsReg,
        assets : assets_reg,
        setAssets : setAssetsReg,
        Euploads : EuploadsReg,
        setEuploads : setEuploadsReg,
        type : 'form',
        path : '/webapi/uploads/newclaim/',
        title : 'Registration images ( back and front ) *',
        setApiError : setApiError,
        max : 2
    }
    const insurance_uploads_props : i_file_uploads_props = {
        uploads : uploads_insurances,
        setUploads : setUploadsInsurances,
        assets : assets_insurances,
        setAssets : setAssetsInsurances,
        Euploads : EuploadsInsurances,
        setEuploads : setEuploadsInsurances,
        type : 'form',
        path : '/webapi/uploads/newclaim/',
        title : 'Insurances images ( back and front ) *',
        setApiError : setApiError,
        max : 2
    }
    const form_props : i_customer_form_props = {
        form : form_values,
        setForm : setFormValues,
        errors : errors_values,
        callback : handleSubmitForm,
        mode : mode,
        dev : dev,
        files : licence_uploads_props,
        prev_form : prev_customer,
        setPrevForm : setPrevCustomer,
        setDisplay : setDisplay,
        loading : loading,
        view : step === 6 ? true : false,
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'customer claims',
    }
    
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple'
    }
    const modal_props : i_prompt_modal_props = {
        open : open,
        title : modal_title,
        handleClose : () => setOpen(false),
        callback : fetchDisable,
        loading : modal_loading,
        mode : mode,
        type : 'disable'
    }
    const claim_headers : i_tbl_header[] = [
        { value : "Date added" , css : { ...header_row, } , portion : 3 , },
        { value : "Name" , css : { ...header_row } , portion : 3 },
        { value : "Email" , css : { ...header_row } , portion : 2 },
        { value : "Phone" , css : { ...header_row} , portion : 2},
        { value : "Type" , css : { ...header_row } , portion : 1 },
        { value : 'Action', css : header_row, portion :1  }
    ]

    const table_v2_props : i_table_v2_props = {
        data : data,
        title : 'No pending claims..',
        loading : loading,
        headers : claim_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "customer_claim",
        pagination : pagination_props
    }
    const cars_props : i_customer_car_form_props = {
        form : form_car,
        setForm : setFormCar,
        errors : errors_car,
        loading : loading,
        return: () => {},
        callback : ( type : t_customer_car_valid_type ) => {},
        dev : dev,
        view : step === 6 ? true : false,
        type : 'single'
    }
    const register_props : i_customer_car_registration_props = {
        form : form_car_registration,
        setForm : setFormCarRegistration,
        errors : errors_car_registration,
        uploads : registration_uploads_props,
        loading : loading,
        type : 'full',
        view : step === 6 ? true : false,
        callback : () => {},
        return : () => {},
        dev : dev
    }
    const insurance_props : i_customer_car_insurance_props = {
        form : form_car_insurance,
        setForm : setFormCarInsurance,
        errors : errors_car_insurance,
        uploads : insurance_uploads_props,
        loading : loading,
        callback : () => {},
        return : () => {},
        view : step === 6 ? true : false,
        type : 'full',
        dev : dev
    }
    console.log(step)
    const steps = [ "Customer details" ,"Customer contacts details" ,"License informations" , "Car informations" , "Car registration informations" , "Car insurance informations" , "Summary"]
    return (
        <Box sx={{ }}>
            <Box sx={{ minHeight : '91vh'}}>
                { loading ? <CircularUnderLoad type={"full"} /> :
                    <Box>
                        {display === 'table' && 
                            <Box>
                                <Box sx={{ display : 'flex' , justifyContent : "center" , paddingTop : '1vh'}}><Typography variant={'h4'}>Customers claims ( zoho forms )</Typography></Box>
                                <Box sx={{ padding: '24px'}}>
                                    <Box sx={tbl_boxing}>
                                        <TblV2 {...table_v2_props} />
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {display === 'form' && 
                        <Box sx={{ marginTop : '1vh'}}>
                        <Stepper sx={{ maxWidth : '1200px' , marginLeft : 'auto' , marginRight : 'auto'}} activeStep={step}>
                        {steps.map((label, index) => {
                              const step_props: { completed?: boolean } = {};
                              const label_props: { optional?: React.ReactNode; } = {};
                              return (
                                <Step key={label} {...step_props}>
                                  <StepLabel {...label_props}>{label}</StepLabel>
                                </Step>
                              );
                          })}
                        </Stepper>
                            <CustomerClaimInputs props={form_props} car_props={cars_props} insurance_props={insurance_props} register_props={register_props} step={step} setStep={setStep} />
                        </Box>
                        }
                    </Box>
                }
                <AlertDialog {...alert_props}/>
                <Modal open={open} onClose={() => setOpen(false)} children={<ModalPromptBody {...modal_props}/>} />
            </Box>
            <Footer type={'center'}/>
        </Box>
    )
}


export default ClaimCustomers