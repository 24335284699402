/*
 * Date: 2024
 * Description: Pay calculator page..
 * Author: Philippe Leroux @ skitsc
 */

import { Box } from "@mui/material"
import { ReactElement , useState , useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

//Interfaces && types
import { i_initial_props , i_alert_props } from "../interfaces/utility.interface"

//Components
import Footer from "../components/utility/footer"
import AlertDialog from "../components/utility/alert"

//Context
import { ThemeContext , MainContext } from "../context/context"

//Utils
import { f_empty_promise , CheckEnv } from "../utils/utility"
import { f_fetch } from "../api/fetch"

//Constants
import {  empty_pay, pay_errors } from "../utils/constant"

//Styles
import { i_complete_pay, i_pay_calculator_props, i_pay_errors } from "../interfaces/payroll.interface"
import PayCalculatorInputs from "../components/payroll/form/pay.calculator"

//Constants
const PayCalculator = ( props : i_initial_props ) : ReactElement => {

    const { mode } = useContext(ThemeContext);
    const { HandleLogout } = useContext(MainContext)
    const nav = useNavigate();
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ api_errors, setApiError ] = useState({ open : false, promise : f_empty_promise()})
    const [ loading , setLoading ] = useState<boolean>(false)

    //Form states
    const [ pay , setPay ] = useState<i_complete_pay>(empty_pay)
    const [ pays_errors ] = useState<i_pay_errors>(pay_errors)

    useEffect(() => {
       const fetchAuth = async() => {
            const res = await f_fetch('/auth', 'POST', true , null)
            if(res.type === "Success") {
            } else {
                if(res.type === 'Unauthorized') HandleLogout(nav)
                setApiError({ open : true, promise : res })
            }
            setLoading(false)
        }
        fetchAuth()
    }, [HandleLogout , nav])
    const handleSubmit = async() => {
    }

    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiError({open : false , promise :f_empty_promise()}),
        type : 'simple'
    }

    const payroll_inputs_props : i_pay_calculator_props = {
        form : pay,
        setForm : setPay,
        errors : pays_errors,
        mode : mode,
        callback : handleSubmit,
        title : '',
        loading : loading,
        dev : dev,
    }
    return (
        <Box>
            <Box sx={{ minHeight : '91.5vh'}}>
                <Box>
                   <PayCalculatorInputs {...payroll_inputs_props} />
                </Box>
            </Box>
            <AlertDialog {...alert_props} />
            <Footer type={'off'}/>
        </Box>
    )
}


export default PayCalculator