/*
 * Date: 2024
 * Description: login page !
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useEffect, useContext , ReactElement , ChangeEvent } from "react";
import { Box ,TextField, IconButton, Typography , Checkbox } from "@mui/material";
import { LoadingButton } from '@mui/lab';

//Contexts
import { MainContext, ThemeContext } from "../context/context";
import { SocketContext } from "../context/socket.context";

//Middlewares
import { m_validate_login } from "../validation/main.middleware";
import { m_validate_email , m_validate_password } from "../validation/utility.middleware";

//Api
import { f_fetch } from "../api/fetch";

//Icons And button
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Styles
import { FormStyles } from "../styles/main.styles"

//Component
import Alert from "../components/utility/alert";

//Interfaces && Types
import { i_user_login } from "../interfaces/user.interface";
import { i_alert_props, i_initial_props } from "../interfaces/utility.interface";
import { i_snack_alert } from "../interfaces/utility.interface";
//Icons
import { empty_promise } from "../utils/constant";

//Utilities
import { f_get_local_key , f_set_local_key , f_kill_storage } from "../utils/utility";
import { default_user_config } from "../utils/constant";

const Login = ( props : i_initial_props ) : ReactElement  => {

    //Contexts
    const { HandleLogin , config } = useContext(MainContext);
    const { mode , primary_color } = useContext(ThemeContext);
    const socket = useContext(SocketContext)
    //Main states
    const [ display , setDisplay ] = useState<'login' | 'recovery' | 'final' | 'reset' | 'register'>('login')
    const [ loading , setLoading ] = useState<boolean>(false);
    const [ api_errors , setApiErrors ] = useState<i_snack_alert>({ open: false, promise: empty_promise });
    const [ store , setStore ] = useState<boolean>(false);
    //Form
    const [ email, setEmail ] = useState<string>("");
    const [ password, setPassword] = useState<string>("");
    const [ visible, setVisible ] = useState<boolean>(false);
    //Errors  
    const [ Eemail , setEemail ] = useState<string>("");
    const [ Epassword , setEpassword ] = useState<string>("");
    
    const handleOnSubmit = async(e : any) => {
        setLoading(true);
        e.preventDefault();
        const clean_login : i_user_login = { email, password }
        const [ valid , error , field ] = m_validate_login(clean_login , display)
        if(valid){
            var endpoint = display === 'login' ? '/login' : display === 'register' ? '/register' : '/recovery';
            const res = await f_fetch(endpoint, 'POST' , true , clean_login);
            if(res.type === "Success"){
                if(display === 'recovery') setDisplay('final')
                if(res.data._id !== undefined) {
                    HandleLogin(res.data);
                    const set = f_get_local_key('config');
                    if(set === null) f_set_local_key('config', default_user_config , 1000 * 60 * 60 * 24)
                    socket.emit('join', { roomId : res.data.type , user_id : res.data._id , socket_id : socket.id })
                }
            }
            setApiErrors({ open: true, promise : res });
        }else{
            if(field === 'email') setEemail(error);
            if(field === "password")  setEpassword(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        if(Eemail!== '' && m_validate_email(email)) setEemail('')
        if(Epassword!== '' && m_validate_password(password)) setEpassword('')
    }, [Eemail , email ,Epassword , password]);
    useEffect(() => {
        var value = f_get_local_key('login_email')
        if(value!== null) {
            setEmail(value)
            setStore(true)
        }
    },[])
    useEffect(() => {
        if(Eemail !== '') setEemail('')
        if(Epassword!== '') setEpassword('')
        if(display !== 'login'){
            setEmail('')
            setPassword('')
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    },[display])

    const handleStore = ( value : boolean ) => {
        if(value){
            f_set_local_key('login_email', email , 2592000000)
            setStore(true)
        }else{
            f_kill_storage('login_email')
            setStore(false)
        }
    }

    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiErrors({ open : false, promise : empty_promise }),
        type : 'full'
    }

    useEffect(() => {  
        const handleKeyPress = (event : any) => {
            if (event.key === 'Enter' && !loading && display!== 'final')  handleOnSubmit(event);
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [loading , email , password]);
    const handleButtonClick = () => {
        const mailtoLink = 'mailto:';
        window.location.href = mailtoLink;
    };
  const pre_path = process.env.REACT_APP_API_URL + '/webapi/public/config/'
  return (
    <Box sx={{}}>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" , backgroundImage : mode === "Dark" ? "url('./images/dark-bg-login.jpg')" :"url('./images/bg-login.jpg')" ,backgroundSize: "cover",  backgroundPosition: "center"}}>
      
      <Box  sx={{ width : '400px',borderRadius: 1 , padding: '2em'}}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", pb: 2}}>
              <Box component={'img'} alt="Garage logo" sx={{ width: '150px' , marginBottom : '1vh'}} src={ display === "login" || display === 'register' ? ( config.path !== '' ? pre_path + config.path : './images/group.svg' ): './images/dark-logo.png'}></Box>
              {display === 'login' || display === 'register' ?
              <>
                <Typography sx={{ fontWeight : '600' , marginBottom : '1vh'}} component="h1" variant="h5">{display === 'login' ? "Log in to your account" : "Don't have an account?"}</Typography>
                <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6">{display === 'login' ? "Welcome back! Please enter your details" : "Start your 30-day free trial." }</Typography>
              </>
              : 
              <>
                <Typography sx={{ fontWeight : '600' , marginBottom : '1vh'}} component="h1" variant="h5">{ display === 'recovery' ? "Forgot password!?" : "Check your email"}</Typography>
                <Typography sx={{ fontSize : 14 , color : 'gray'}} component="h2" variant="h6">{ display === 'recovery' ? "No worries, we'll send you reset instructions." : "We sent a password reset link to"}</Typography>
                { display === 'final' && <Typography sx={{ fontSize : 14 , color : 'gray'}}>{email}</Typography>}
                </>
              }
            </Box>
            <Box component={'form'}>
            {display !== 'final' &&
              <>
              <Typography sx={{ fontWeight : 600}}>Email</Typography>
                <TextField  fullWidth  size="small" placeholder="Enter your email address" sx={FormStyles.textfield} autoComplete="new-password"
                value={email} helperText={Eemail} error={Eemail !== "" ? true : false}
                InputLabelProps={{ shrink: true }} 
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(e.target.value)}/>
              </>
            }
            {display === 'login' && <>
              <Typography sx={{ fontWeight : 600}}>Password</Typography>
              <TextField fullWidth  size="small" type={visible ? "text" : "password"} placeholder="Enter your password" sx={FormStyles.textfield} autoComplete="new-password"
                value={password} helperText={Epassword} error={Epassword !== "" ? true : false}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ endAdornment: <IconButton edge="end" aria-label="toggle password visibility" 
                onClick={() => setVisible(!visible)}>{visible ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>}}/>
                
                  <Box sx={{ display : 'flex' , marginBottom : '1vh'}}>
                    <Checkbox disabled={email.length === 0 ? true : false} checked={store} onChange={(e) => handleStore(e.target.checked)} sx={{ marginLeft : '-10px'}}/>
                    <Typography sx={{ alignSelf : 'center' , fontSize : 14}}>Remember for 30 days</Typography>
                    <Typography sx={{ alignSelf : 'center' , marginLeft : 'auto' , fontSize : 14 , color : primary_color , cursor : 'pointer'}}
                    onClick={() => !loading && setDisplay(display === 'login' ? 'recovery' : 'login')}>{ "Forgot password" }</Typography>
                  </Box>
                  </>
                }
            <LoadingButton loading={loading} disabled={loading} fullWidth variant="contained" color="primary" onClick={(e) => display === 'final' ? handleButtonClick() : handleOnSubmit(e)}>{display === 'login' ? "Sign in" : display === 'recovery' ? "Reset password" : display === 'final' ? 'Open email app' : 'Send request'}</LoadingButton>
          </Box>
          { display === 'final' && 
            <Box sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '2vh'}}>
              <Typography sx={{ paddingRight : '3px'}}>Didn't receive the email ?</Typography>
              <Typography sx={{ cursor : 'pointer' , color : '#B71F26'}} onClick={(e) => handleOnSubmit(e)}>Click to resend</Typography>
            </Box>
          }
          { display === 'login' || display === 'register' ?
            <Box>
              {display === 'login' &&
                <Box sx={{ marginTop : '1.5vh' , display : 'flex' , justifyContent : 'center' , border : '1px solid gray' , padding : '4px' , cursor : 'pointer'}}>
                  <Box sx={{ paddingRight : '8px'}} component={'img'} alt={'Google icon img'} src={'./images/google-icon.svg'}></Box>
                  <Typography onClick={() => { setApiErrors({ open : true , promise : { type : 'Success' , message : 'Implement me please..' , data : []}})}} sx={{ fontWeight : '600' , alignSelf : 'center'}}>Sign in with google</Typography>
                </Box>
              }
                <Box sx={{ marginTop : '2vh' , display : 'flex' , justifyContent : 'center'}}>
                  <Typography>{display === 'login' ? "Don't have an account?" : "Already have an account ?"} </Typography>
                  <Typography onClick={() => setDisplay( display === 'login' ? 'register' : 'login')} sx={{ paddingLeft : '6px' , color : primary_color , cursor : 'pointer'}}>{display === 'login' ? "Sign up" : "Log in"}</Typography>
                </Box>
            </Box>
            :
            <Box sx={{ marginTop : '2vh' , display : 'flex' , justifyContent : 'center' , marginLeft : '-4%'}}>
              <IconButton onClick={() => !loading && setDisplay('login')}>
                <ArrowBackIcon/>
              </IconButton>
              <Typography sx={{ alignSelf : 'center'}}>Back to login</Typography>
            </Box>
          }
          </Box>
          </Box>
        <Alert {...alert_props} />
      </Box>
      </Box>
  );
}

export default Login;
