import React, { useEffect, useState } from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
} from "@react-pdf/renderer";
import {
	i_customer,
	i_customer_car,
	i_insurance,
} from "../../../interfaces/customer.interface";
import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from "@mui/material";

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#ffffff",
		padding: 20,
		display: "flex",
		flexDirection: "column",

		// border: "1px solid black",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
		gap: 10,
		justifyContent: "center",
	},
	title: {
		fontSize: 24,
		marginBottom: 10,
	},
	image: {
		width: 500,
		height: 300,
		// display:'block'
	},
});
interface i_repair_choice_props {
	customer: i_customer;
	customer_cars: i_customer_car[];
	onDataChange?: (data: any) => void;
	data: { [key: string]: any };
	signature?: string;
	formError?: string;
	fieldError?: string;
}
const RepairChoice = (props: i_repair_choice_props) => {
	const { data } = props;
	const targetCar = props.customer_cars?.find((el) => el._id == data.car_id);
	const insurance = targetCar?.insurance.find(
		(el) => el.company_name == data.insurance
	);
	return (
		<Document>
			<Page style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>Confirmation du choix du réparateur</Text>
					<Text>
						Je soussigné(e){" "}
						<Text style={{ textDecoration: "underline" }}>
							{props.customer?.first_name} {props.customer?.middle_name}{" "}
							{props.customer?.last_name}
						</Text>
						, confirme à mon assureur{" "}
						<Text style={{ textDecoration: "underline" }}>
							{data.insurance}
						</Text>{" "}
						avoir choisi Carrosserie Auto Prestige, située au 8680 rue
						Lafrenaie. Montréal (HIP 285), pour effectuer la réparation de mon
						véhicule.
					</Text>
					<Text>
						Je vous demande de bien vouloir contacter directement la carrosserie
						afin de convenir dun rendez-vous et des modalités de réparation avec
						le carrossier. Toute discussion relative aux taux horaires ou aux
						méthodes de réparation spécifiques devra étre menée exclusivement
						avec le garage. veuillez également prendre note de l'application du
						document Cession de créance (1637 c.cQ).
					</Text>
					<Text>
						N° Dossier :{" "}
						<Text style={{ textDecoration: "underline" }}>{data.dossier}</Text>
					</Text>
					<Text style={{ alignItems: "flex-start" }}>
						N° Police :{" "}
						<Text style={{ textDecoration: "underline" }}>
							{insurance?.no_police || ""}
						</Text>
					</Text>
				</View>
				<View>
					<View
						style={{
							flexDirection: "row",
							marginTop: 10,
							alignItems: "center",
							gap: 5,
						}}
					>
						<Text>Signature:</Text>
						<View style={{ height: 100, width: 300 }}>
							{props.signature && <Image src={props.signature} />}
						</View>
						<View style={{ marginLeft: 10 }}>
							<Text>Date: {new Date().toLocaleDateString()}</Text>
						</View>
					</View>
				</View>
				<Text>
					Note :{" "}
					<Text style={{ textDecoration: "underline" }}>{data.note}</Text>
				</Text>
			</Page>
		</Document>
	);
};
const RepairChoiceForm = (props: i_repair_choice_props) => {
	const [car, setCar] = useState<i_customer_car>(props.customer_cars[0]);
	const [dossier, setDossier] = useState<string>("");
	const [insurance, setInsurance] = useState<i_insurance>(car.insurance[0]);
	const [note, setNote] = useState<string>("");
	const handleCarChange = (event: SelectChangeEvent<string>) => {
		let car = props.customer_cars?.find((el) => el._id == event.target.value);
		if (!car) return;
		setCar(car);
	};
	const handleInsuranceChange = (event: SelectChangeEvent<string>) => {
		let i = car.insurance.find((el) => el.company_name == event.target.value);
		if (!i) return;
		setInsurance(i);
	};
	useEffect(() => {
		if (props.onDataChange)
			props.onDataChange({
				car_id: car._id,
				insurance: insurance.company_name,
				note,
				dossier,
			});
	}, [car, insurance, note, dossier]);

	return (
		<>
			<FormControl fullWidth>
				<Select
					id="car-select"
					value={car._id || ""}
					label="Car"
					onChange={handleCarChange}
				>
					{props.customer_cars?.map((c: i_customer_car) => {
						return (
							<MenuItem value={c._id} key={c._id}>
								{c.make} {c.model}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<FormControl fullWidth>
				<Select
					id="insurance"
					value={insurance.company_name}
					label="Insurance"
					onChange={handleInsuranceChange}
				>
					{car.insurance.map((i: i_insurance) => {
						return (
							<MenuItem value={i.company_name} key={i.company_name}>
								{i.company_name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<FormControl fullWidth>
				{props.fieldError && props.fieldError == "dossier" && (
					<Typography color={"error"}>{props.formError}</Typography>
				)}
				<TextField
					value={dossier}
					onChange={(e) => setDossier(e.target.value)}
					label="Dossier"
					/>
			</FormControl>
			<FormControl fullWidth>
					{props.fieldError && props.fieldError == "dossier" && (
						<Typography color={"error"}>{props.formError}</Typography>
					)}
				<TextField
					minRows={10}
					value={note}
					onChange={(e) => setNote(e.target.value)}
					label="Note"
					multiline
				/>
			</FormControl>
		</>
	);
};
export { RepairChoiceForm };

export default RepairChoice;
