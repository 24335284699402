/*
 * Date: 2024
 * Description: Timesheet inputs
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Grid , Typography , TextField, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { ReactElement , ChangeEvent, useEffect , useState } from "react"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs"

//Interfaces && types
import { i_timesheet_inputs_props } from "../../../interfaces/timesheet.interface"
import { i_user } from "../../../interfaces/user.interface";
//Components

//Utils

//Constants
import { pay_periods , empty_user } from "../../../utils/constant";
//Styles
import { sub_title , input_base , bottom_line } from "../../../styles/form.styles";
import { getPayPeriodFromDate } from "../../../utils/utility";
import { i_period_data } from "../../../interfaces/utility.interface";

const TimesheetInputs = ( props : i_timesheet_inputs_props ) : JSX.Element => {
    const F = props.form;
    const E = props.errors;
    const setCurrentDate = (type : 0 | 1) => {
        const timestamp = new Date().valueOf()
        const period : i_period_data | null = getPayPeriodFromDate(props.periods , timestamp)
        if(period!== null){
            return dayjs(type === 0 ? period.monday : period.sunday)
        }
        return null
    }
    const setCurrentPeriod = () => {
        const timestamp = new Date().valueOf()
        const period : i_period_data | null = getPayPeriodFromDate(props.periods , timestamp)
        if(period!== null){
            return period.period
        }
        return null
    }
    const [ start_date , setStartDate ] = useState<Dayjs | null>(setCurrentDate(0))
    const [ end_date , setEndDate ] = useState<Dayjs | null>(setCurrentDate(1))
    const [ pay_period, setPayPeriod ] = useState<number | null>(setCurrentPeriod())
    const handleDate = (date : Dayjs | null ) => {
        if(date !== null){
            const timestamp = date.valueOf()
            console.log(timestamp)
            const period : i_period_data | null = getPayPeriodFromDate(props.periods , timestamp)
            console.log(period)
            if(period!== null){
                setStartDate(dayjs(period.monday))
                setEndDate(dayjs(period.sunday))
            }
        }

    }
    const highlightedRangeStyles = {
        background: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
      };
      const renderDay = (day: Date) => {
        const date = dayjs(day);
        const isWithinRange =
          start_date &&
          end_date &&
          date.valueOf() >= start_date.valueOf() &&
          date.valueOf() <= end_date.valueOf();
    
        return (
          <div style={isWithinRange ? highlightedRangeStyles : undefined}>
            {date.format('D')}
          </div>
        );
      };
    const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        if(name === 'pay_period'){
            const find = props.periods.find(period => period.period === Number(value))
            if(find !== undefined){
                setStartDate(dayjs(find.monday))
                setEndDate(dayjs(find.sunday))
                setPayPeriod(Number(value))
            }
        }
    }
    const setUser = ( val : string ) => {
        const row = props.users.find( (x : any) => x._id === val)
        props.setForm({ ...F , user : row ? row : empty_user})
    }
    const bi_weekly_period = pay_periods.slice(0, Math.floor(pay_periods.length / 2));
    return (
        <Box p={2} sx={{ marginBottom : '1vh' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>Add a new timesheet</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Pay period</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField select sx={[input_base , { width : '100px'}]} name={"pay_period"} value={pay_period} onChange={handleChange} label={"Pay period"}>
                        {bi_weekly_period.map((value : number) => (
                            <MenuItem key={value} value={value} >{value + 1}</MenuItem>
                        ))}
                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker sx={[input_base , { width : '200px'}]} label="Beginning on" value={dayjs(start_date)} onChange={(newValue : Dayjs | null) => handleDate(newValue )}/>
                        <DatePicker sx={[input_base , { width : '200px'}]} label="End on"  value={dayjs(end_date)} disabled/>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Select an employee</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField select name={"user"} sx={[input_base , { width : '300px'} ]} size="small" value={F.user._id !== ''  ? F.user._id : "default"} error={E.user_id !== ''} helperText={E.user_id}
                        onChange={( event: ChangeEvent<any> ) => setUser(event.target.value)} autoComplete={props.dev ? "off" : "new-password" }>
                            <MenuItem value={"default"} disabled>Select a user</MenuItem>
                            {props.users.map((user : i_user) => (
                                <MenuItem key={user._id} value={user._id}>{user.first_name + " " + user.last_name + " --> " + user.type }</MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            </Grid>
        </Box>
    )
}

export default TimesheetInputs