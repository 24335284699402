

/*
 * Date: 2024
 * Description: Payroll inputs ( super calcs)
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { useState } from "react";
import { Box , Grid , Typography , TextField , MenuItem, InputAdornment} from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { ReactElement , ChangeEvent, useEffect } from "react"

//Components
//Interfaces && types
import { i_cumulator, i_pay_calculator_props } from "../../../interfaces/payroll.interface";

//Constants 
import { default_cumulator , pay_periods } from "../../../utils/constant";
import { calcQcTaxableFed , calcQcTaxes , calcQcTaxable , calcRQAPtaxes , calcAeTaxes , calcRRQtaxes , customCeil , cRRQTaxes , cAETaxes , cRQAPTaxes} from "../../../utils/calculators";

//Styles
import { sub_title , input_base , bottom_line } from "../../../styles/form.styles";
import NumericTextField from "../../utility/inputs/numeric.inputs";

const PayCalculatorInputs = ( props : i_pay_calculator_props ) : ReactElement=> {
    const F = props.form;
    const E = props.errors;
    const [ pay_count , setPayCount ] = useState<number>(26)
    const [ week , setWeek ] = useState<number>(0)
    const [ cumulator , setCumulator ] = useState<i_cumulator>(default_cumulator)

    const handleCalc = () => {
        const P = pay_count
        const total_gross = ( F.hours * Number(F.rate) ) + Number(F.extra)
        const estimated_total  = total_gross * P 
        const reer : number = Number(F.reer) ? Number(F.reer) : 0
        const syndical : number = Number(F.syndical) ? Number(F.syndical) : 0
        const qc_ri = Number(calcQcTaxable(estimated_total , P , week , reer ))
        const [ c , C ] = calcRRQtaxes(estimated_total)
        const y_rqap = Number(calcRQAPtaxes(estimated_total))
        const y_ae = Number(calcAeTaxes(estimated_total))
        const y_qc_tax = Number(calcQcTaxes(qc_ri))
        const y_fed_tax = Number(calcQcTaxableFed(estimated_total, P , week , reer , syndical))
        const y_deductions = y_ae + y_qc_tax + y_fed_tax + c + C + y_rqap 
        var cumul_qc_tax = 0
        for(var i = 0 ; i < ( week + 1 ) ; i++) cumul_qc_tax += calcQcTaxes(qc_ri) / P
        var cumul_fed_tax = 0
        for(var x = 0 ; x < ( week + 1 ) ; x++) cumul_fed_tax += calcQcTaxableFed(estimated_total , P , x , reer , syndical) / P
        const [ cb , CB , rrq_cumul] = cRRQTaxes(estimated_total , P , week)
        const [ w_rqap , c_rqap , l_rqap ] = cRQAPTaxes(estimated_total , P , week)
        const [ w_ae , c_ae , l_ae ] = (cAETaxes(estimated_total , P , week)) 
        const b_qc_tax = Number(calcQcTaxes(qc_ri)) / P
        const b_fed_tax = Number(calcQcTaxableFed(estimated_total , P , week , reer , syndical)) / P
        const b_deductions = w_ae + b_qc_tax + b_fed_tax + (cb + CB) + w_rqap
        const net_calc = ( total_gross * ( week + 1) ) - ( b_deductions * ( week + 1) )
        props.setForm({ ...F , 
        year_calc : { gross : estimated_total , net : estimated_total - y_deductions , ae : y_ae ,
             rrq : c + C , rqap :  y_rqap, qc_tax : y_qc_tax, fed_tax : y_fed_tax, rate : estimated_total > 0 ? taxRate(y_deductions , estimated_total) : 0 ,
            type : 'year' , lord_ae : 0 , lord_rqap : 0 , lord_rrq : c + C , vac_cumul : 0 , syndical : 0 , extra : 0 , reer : 0},
        biweekly_calc : { gross : total_gross , net : total_gross - b_deductions , ae : w_ae ,
             rrq :  ( (cb + CB) ) , rqap : w_rqap , qc_tax : b_qc_tax, fed_tax : b_fed_tax, rate : total_gross > 0 ? taxRate(b_deductions , total_gross) : 0 ,
            type : 'biweekly' , vac_cumul : 0 , lord_ae : 0, lord_rqap :  0, lord_rrq : (cb + CB) , syndical : 0 , extra : 0 , reer : 0 }})
        setCumulator({ raw : total_gross * ( week + 1), net :  net_calc, qc_tax : cumul_qc_tax , fed_tax : cumul_fed_tax, rrq_tax : rrq_cumul, rqap_tax : c_rqap, ae_tax : c_ae , lord_ae : l_ae , lord_rqap : l_rqap , lord_rrq : rrq_cumul })
    }
    useEffect(() => {
        handleCalc()
    },[F.hours, F.rate , week , pay_count , F.extra , F.reer , F.syndical])
    const handleValues = (amount: string , value : 'rate' | 'extra' | 'reer' | 'syndical') => {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (amount === '') {
            props.setForm({...F, [value]: ''});
        } else if (regex.test(amount)) {
            props.setForm({...F, [value]: amount});
        }
    }
    const taxRate = ( deductions : number , amount : number ) => {
        const rate = (deductions / amount) * 100;
        const roundedRate = rate.toFixed(1);
        return parseFloat(roundedRate);
    }

    const handleHours = ( e : number) => {
        var value : number = e
        if(pay_count === 26) value = value >= 80 ? 80 : value
        if(pay_count === 52) value = value >= 40 ? 40 : value
        props.setForm({...F, hours : value})
    }
    const handleChangeWeek = ( e :ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setWeek(Number(value))
    }
    const handleChange = ( e :ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setWeek(0)
        props.setForm({...F, hours : Number(value) === 26 ? 80 : 40 })
        setPayCount(Number(value))
    }
    const bi_weekly_period = pay_periods.slice(0, Math.floor(pay_periods.length / 2));
  return (
    <Box p={2} sx={{ marginBottom : '1vh' }} id={'task_inputs'} >
        <Grid container>
            <Grid item xs={12}>
                <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>Estimate a pay to an employee</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Pay count / Pay period</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '150px'} ]}  size="small" select value={pay_count} onChange={handleChange}  autoComplete={props.dev ? "off" : "new-password" }>
                    <MenuItem key={26} value={26}>26</MenuItem>
                    <MenuItem key={52} value={52}>52</MenuItem>
                </TextField>
                <TextField sx={[input_base , { width : '150px'} ]}  size="small" select value={week} onChange={handleChangeWeek} autoComplete={props.dev ? "off" : "new-password" }>
                    {pay_count === 26 ? bi_weekly_period.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option + 1}
                    </MenuItem>
                )) :
                    pay_periods.map((option) => (
                    <MenuItem key={option} value={option}>
                            {option + 1}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>


            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Hours rates / Hours / extra / reer / syndical</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"Hour rate"} value={F.rate} error={false} helperText={""}
                      onChange={(e : any) => handleValues(e.target.value , 'rate')} autoComplete={props.dev ? "off" : "new-password" } name={"rates"} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} />  
                    <NumericTextField sx={[input_base , { width : '150px'} ]} size="small" placeholder={"index"} value={F.hours} error={E.hours !== ""} helperText={E.hours}
                      handleChange={handleHours} autoComplete={props.dev ? "off" : "new-password" } name={"hours"}/>   
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"Extra amount"} value={F.extra} error={false} helperText={""}
                      onChange={(e : any) => handleValues(e.target.value , 'extra')} autoComplete={props.dev ? "off" : "new-password" } name={"extra"} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} />  
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"Reer $"} value={F.reer} error={false} helperText={""}
                      onChange={(e : any) => handleValues(e.target.value , 'reer')} autoComplete={props.dev ? "off" : "new-password" } name={"reer"} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} /> 
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"Syndical $"} value={F.syndical} error={false} helperText={""}
                      onChange={(e : any) => handleValues(e.target.value , 'syndical')} autoComplete={props.dev ? "off" : "new-password" } name={"syndical"} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}} />   
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 , marginBottom : '2vh'}}>Total deductions from this year</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Raw / Net</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField value={cumulator.raw.toFixed(2)} disabled name={'raw'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                    autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                   <TextField value={cumulator.net.toFixed(2)} disabled name={'net'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Qc taxes / Fed taxes / RRQ / RQAP / Ae</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField value={cumulator.qc_tax.toFixed(2)} disabled name={'qc_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                    autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                   <TextField value={cumulator.fed_tax.toFixed(2)} disabled name={'fed_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={cumulator.rrq_tax.toFixed(2)} disabled name={'rrq_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={cumulator.rqap_tax.toFixed(2)} disabled name={'rqap_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                       <TextField value={cumulator.ae_tax.toFixed(2)} disabled name={'ae_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Employer RRQ / RQAP / Ae</Typography>
                </Grid>
                <Grid item xs={9}>
                      <TextField value={cumulator.lord_rrq.toFixed(2)} disabled name={'rrq_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={cumulator.lord_rqap.toFixed(2)} disabled name={'rqap_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                       <TextField value={cumulator.lord_ae.toFixed(2)} disabled name={'ae_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 , marginBottom : '2vh'}}>{pay_count === 26 ? "Bi-Weekly taxes calculation" : "Weekly taxes calculation"}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Raw / Net</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"bi-weekly gross amount"} disabled value={F.biweekly_calc.gross} 
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  autoComplete={props.dev ? "off" : "new-password" } name="gross_amount"/>      
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"Net amount"} value={F.biweekly_calc.net.toFixed(2)}
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="net_amount"/>
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"tax rate"} value={F.biweekly_calc.rate.toFixed(2)}
                     InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="taxation"/>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Qc taxes / Fed taxes / RRQ / RQAP / Ae</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField value={F.biweekly_calc.qc_tax.toFixed(2)} disabled name={'qc_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                    autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                   <TextField value={F.biweekly_calc.fed_tax.toFixed(2)} disabled name={'fed_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.biweekly_calc.rrq.toFixed(2)} disabled name={'rrq_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.biweekly_calc.rqap.toFixed(2)} disabled name={'rqap_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                       <TextField value={F.biweekly_calc.ae.toFixed(2)} disabled name={'ae_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 , marginBottom : '2vh'}}>Yearly taxes calculation</Typography>

                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Raw / Net</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '150px'} ]}  size="small" placeholder={"index"} disabled value={F.year_calc.gross} 
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  autoComplete={props.dev ? "off" : "new-password" } name="gross_amount"/>       
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"Net amount"} value={F.year_calc.net.toFixed(2)} 
                     InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="net_amount"/>
                    <TextField sx={[input_base , { width : '150px'} ]} disabled  size="small" placeholder={"tax rate"} value={F.year_calc.rate.toFixed(2)}
                     InputProps={{endAdornment: <InputAdornment position="start">%</InputAdornment>}}   autoComplete={props.dev ? "off" : "new-password" } name="taxation"/>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Qc taxes / Fed taxes / RRQ / RQAP / Ae</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField value={customCeil(F.year_calc.qc_tax)} disabled name={'qc_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                    autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                   <TextField value={customCeil(F.year_calc.fed_tax)} disabled name={'fed_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.year_calc.rrq.toFixed(2)} disabled name={'rrq_tax'} sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                      <TextField value={F.year_calc.rqap.toFixed(2)} disabled  name={'rqap_tax'}  sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                       <TextField value={F.year_calc.ae.toFixed(2)} disabled name={'ae_tax'}  sx={[input_base , { width : '150px'} ]} size={'small'} 
                   autoComplete={props.dev ? "off" : "new-password" } placeholder="0"  InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}  />
                </Grid>
        </Grid>

        <hr style={bottom_line}/>
        <Grid container sx={{ marginTop : '1vh' , marginBottom : '1vh'}}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Save those values ?</LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}

export default PayCalculatorInputs