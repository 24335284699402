
/*
 * Date: 2024
 * Description: Reusable utility functions
 * Author: Philippe Leroux @ skitsc
 */

//Interfaces && types
import { t_method, t_user_fields } from "../types/types";
import { i_period_data, i_promise , i_search_filter } from "../interfaces/utility.interface";
import { i_order_form_fields } from "../interfaces/order.interface";
import { GridValueFormatterParams } from "@mui/x-data-grid/models"
import { i_customer, i_customer_licence } from "../interfaces/customer.interface";

const f_set_first_letter = (input: string): string => {
  if (input.length === 0)   return input
  const firstLetter = input[0].toUpperCase();
  const restOfString = input.slice(1);
  return firstLetter + restOfString;
}
const f_empty_promise = (): i_promise => {
  const promise: i_promise = {
    message: "",
    type: "Failed",
    data: [],
  };
  return promise;
};
const delay = (ms : number) : Promise<void> => new Promise(res => setTimeout(res, ms));

const f_validate_num = ( input: number ) : 0 | 1 => {
  if (input === 0 || input === 1) 
    return input;
  else 
    return 0;
}
const f_validateField = (field: string): field is t_user_fields => {
  return ['email', 'first_name', 'last_name', 'phone_one', 'phone_two' , 'address', 'password' , 'path'].includes(field as t_user_fields);
};
const f_format_order = ( order : i_order_form_fields , files : File[] ,  method : t_method , assets : string[] ) : FormData => {
  const form = new FormData();
  form.append('title', order.title)
  form.append('description', order.description)
  if(method === 'POST') form.append('number', order.number)
  form.append('user_id' , order.user_id)
  form.append('car_id' , order.car_id)
  if(order.customer_id !== null){
    var value = order.customer_id
    if(value === 'none' || value === 'default'){
       form.append('customer_id' , '')
    }else{
      form.append('customer_id' , value)
    }
  }
  for(var x : number = 0; x < files.length; x++)
    form.append('files' , files[x])
  if(method === 'PUT'){
    form.append('_id' , order._id)
    for(var y : number = 0; y < assets.length; y++)
    form.append('assets[]' , assets[y])
  }
  return form
}
const f_is_Today = (date : Date) => {
  const today = new Date();
  if (today.toDateString() === date.toDateString()) return true;
  return false;
}
// const f_is_Day = (param : number, date :Date) =>{
//   var today = new Date();
//   today.setDate(today.getDate() - param);
//   if ((today.toDateString()) === date.toDateString())return true;
//   return false;
// }
const f_is_Year = (date : Date) => {
  const today = new Date();
  if (today.getFullYear() === date.getFullYear())return true;
  return false;
}
const pad = (value : number) => {
  if(value < 10) {
      return '0' + value;
  } else {
      return value;
  }
}
const f_timestamp_to_date = ( timestamp : number | GridValueFormatterParams | string , type : 'short' | 'long' ) => {
  if(timestamp == null){
      return 0
  }else{
      if(Number(timestamp)){
          var timez : number = Number(timestamp)
          const UNIX_timestamp = new Date(timez * 1000)
          const is_today = f_is_Today(UNIX_timestamp)
          const is_year = f_is_Year(UNIX_timestamp)
          var time : string = '';
          var a : Date = new Date(UNIX_timestamp);
          var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          var year : number = a.getFullYear();
          var month : string = months[a.getMonth()];
          var short_month : number = a.getMonth() + 1;
          var date : number = a.getDate();
          var hour : number = a.getHours();
          var min : string | number = pad(a.getMinutes());
          if(type !== undefined && type === 'short'){
              if(!is_today){
                  var str_year = String(year)
              time =  date + '/' + short_month + '/' + str_year.slice(2); 
              }else{
                  var moment = 'A.M'
                  if(hour > 12){
                      moment = 'P.M'
                      hour = hour - 12
                  }
                  time = hour + ':' + min + ' ' + moment;
              }
              return time
          }

          if(is_today === undefined || is_year === undefined){
              time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
              return time
          }
          if(is_today && is_year){
              time = "Today " +hour + ':' + min;
              return time
          }
              time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
          return time; 
      }
      }
   
}
  const f_return_color = ( select : number ) => {
    if(select === 1) return '#f44336'
    if(select === 2) return '#304ffe'
    if(select === 3) return '#b388ff'
    if(select === 4) return '#ffeb3b'
    if(select === 5) return '#4caf50'
    if(select === 6) return '#ef6c00'
    return '#000000'
  }
  const CheckEnv = ( ) : boolean => {
    if(process.env.REACT_APP_ENV === 'dev'){
      return true
    }else{
      return false
    }
  }
  const f_format_phone_cute = ( ugly_phone : string ) => {
    var cute_phone = ugly_phone;
    var l = cute_phone.length
    if(l > 7 && l === 11)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{3})(\d{1,4})$/, "$1-($2)-$3-$4")
    if(l > 7 && l === 10)cute_phone = ugly_phone.replace(/(\d{3})(\d{3})(\d{1,4})$/, "($1)-$2-$3")
    if(l > 4 && l <= 7)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{1,3})$/, "$1-($2)-$3")
    if(l > 1 && l <= 4)cute_phone = ugly_phone.replace(/(\d{1})(\d{1,3})$/, "$1-($2)")
    if(l === 1)cute_phone = ugly_phone.replace(/(\d{1})$/, "$1-")
    return cute_phone 
}
const f_make_customer_fd = ( data : i_customer , method : t_method , uploads : any[] , assets : string[] ) : FormData => {
  const formData = new FormData();
  formData.append('_id', data._id);
  formData.append('customer_type', data.customer_type);
  formData.append('title', data.title);
  formData.append('first_name', data.first_name);
  formData.append('middle_name', data.middle_name);
  formData.append('last_name', data.last_name);
  formData.append('company_name', data.company_name);
  formData.append('email', data.email);
  formData.append('phone_one', data.phone_one);
  if(data.phone_two!== undefined) formData.append('phone_two', data.phone_two)
  formData.append('display_name', data.display_name);
  formData.append('password', '');
  formData.append('attendance', data.attendance);
  formData.append('unit_number', data.unit_number);
  formData.append('door_number', data.door_number);
  formData.append('street', data.street);
  formData.append('city', data.city);
  formData.append('state', data.state);
  formData.append('zip', data.zip);
  formData.append('contact_array', JSON.stringify(data.contact_array));
  formData.append('tax_preference', data.tax_preference);
  formData.append('tax_rate', data.tax_rate);
  formData.append('currency', data.currency);
  formData.append('payment_terms', data.payment_terms);
  formData.append('portal_language', data.portal_language);
  formData.append('welcome_email', data.welcome_email.toString());
  const formated_licence : i_customer_licence = { ...data.licence , expiration_date : Math.floor(data.licence.expiration_date / 1000) , delivery_date : Math.floor(data.licence.delivery_date / 1000) , birth_date : Math.floor(data.licence.birth_date / 1000) }
  formData.append('licence' , JSON.stringify(formated_licence));
  formData.append('notes', data.notes);
  formData.append('complete' , data.complete.toString());
  if(method === 'POST' && uploads.length > 0) for(var z : number = 0; z < uploads.length; z++) formData.append('files', uploads[z]);
  if(method === 'PUT'){
    for(var i : number = 0 ; i < assets.length ; i++)  formData.append('path', assets[i]);
    for(var x : number = 0 ; x < uploads.length ; x++) formData.append('files', uploads[x]);
  }
  return formData
}
const f_set_local_key = (key : string , input_value : any, time : number ) => {
  const now = new Date()
  const item = {
      value: input_value,
      expiry: now.getTime() + time,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

const f_get_local_key = ( key : string ) => {
  const itemStr = localStorage.getItem(key)
  if(!itemStr) return null
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
      f_kill_storage(key)
      return null
  }
  return item.value
}
const f_kill_storage = ( key : string ) => {
  localStorage.removeItem(key)
}

const f_darken_color = (hex : string, factor : number) => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    const newR = Math.round(r * (1 - factor));
    const newG = Math.round(g * (1 - factor));
    const newB = Math.round(b * (1 - factor));
    const clamp = (value : number) => Math.min(255, Math.max(0, Math.round(value)));
    const newHex = `#${clamp(newR).toString(16).padStart(2, '0')}` +
                  `${clamp(newG).toString(16).padStart(2, '0')}` +
                  `${clamp(newB).toString(16).padStart(2, '0')}`;

    return newHex;
}
const f_lighten_hex_color = (hex: string, percent: number): string  =>{
  percent = Math.min(100, Math.max(0, percent));
  let r: number = parseInt(hex.slice(1, 3), 16);
  let g: number = parseInt(hex.slice(3, 5), 16);
  let b: number = parseInt(hex.slice(5, 7), 16);
  r += Math.round((255 - r) * (percent / 100));
  g += Math.round((255 - g) * (percent / 100));
  b += Math.round((255 - b) * (percent / 100));
  const newHex = `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g).toString(16).padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`;

  return newHex;
}
const f_make_cute_date = ( date : Date ) : string => {
  const hours = date.getHours();
  const amOrPm = hours < 12 ? 'AM' : 'PM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
  return formattedTime;
}
const f_encode_query_data = (filter: i_search_filter): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(filter).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item) => params.append(`${key}[]`, item.toString()));
      } else {
        params.append(key, value.toString());
      }
    }
  });
  return params;
};
const formatSIN = ( sin : string) => {
    const cleanSIN = sin.replace(/\D/g, '');
    return cleanSIN.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
};
const countDigits = ( value : string) => {
    const digitRegex = /\d/g;
    const matches = value.match(digitRegex);
    return matches ? matches.length : 0;
};
const getPayPeriod = (date : Date , biweekly : boolean): i_period_data => {
    const year: number = date.getFullYear();
    const yearStart: Date = new Date(year, 0, 1);
    const difference: number = date.getTime() - yearStart.getTime();
    const daysPassed: number = Math.ceil(difference / (1000 * 3600 * 24));
    let period: number;
    let monday: Date;
    let sunday: Date;
    if (daysPassed <= 6) {
        period = 1;
        monday = new Date(yearStart);
        sunday = new Date(yearStart.getTime() + (6 * 24 * 3600 * 1000));
    } else {
        if (biweekly) {
            const totalDays = daysPassed - 6;
            const biweeklyPeriods = Math.floor((totalDays - 1) / 14);
            const remainingDays = totalDays - biweeklyPeriods * 14;
            period = biweeklyPeriods * 2 + 1;
            if (remainingDays <= 6) {
                monday = new Date(yearStart.getTime() + (biweeklyPeriods * 14 * 24 * 3600 * 1000));
            } else {
                period++;
                const daysToMonday = 7 - (yearStart.getDay() || 7);
                monday = new Date(yearStart.getTime() + (daysToMonday * 24 * 3600 * 1000) + ((biweeklyPeriods) * 14 * 24 * 3600 * 1000));
            }
            sunday = new Date(monday.getTime() + (13 * 24 * 3600 * 1000)); // Sunday is 13 days later
        } else {
            const weeklyPeriods = Math.floor((daysPassed - 6) / 7);
            period = weeklyPeriods + 1;
            const daysToMonday = 7 - (yearStart.getDay() || 7);
            monday = new Date(yearStart.getTime() + (daysToMonday * 24 * 3600 * 1000) + ((weeklyPeriods) * 7 * 24 * 3600 * 1000));
            sunday = new Date(monday.getTime() + (6 * 24 * 3600 * 1000)); // Sunday is 6 days later
        }
    }
    return {
        period: period,
        monday: monday.valueOf(),
        sunday: sunday.valueOf()
    };
}
const getPayAllPeriods = (year: number, biweekly: boolean): i_period_data[] => {
    const yearStart: Date = new Date(year, 0, 1);
    const payPeriods: i_period_data[] = [];
    let currentMonday: Date = new Date(yearStart);
    let currentSunday: Date = new Date(yearStart.getTime() + (6 * 24 * 3600 * 1000)); // First Sunday

    let period = 0;
    while (currentMonday.getFullYear() === year) {
        payPeriods.push({
            period: period,
            monday: currentMonday.getTime(),
            sunday: currentSunday.getTime()
        });

        // Move to the next pay period
        if (biweekly) {
            currentMonday = new Date(currentSunday.getTime() + (1 * 24 * 3600 * 1000)); // Move to next Monday
            currentSunday = new Date(currentMonday.getTime() + (13 * 24 * 3600 * 1000)); // Move to Sunday two weeks later
        } else {
            currentMonday = new Date(currentSunday.getTime() + (1 * 24 * 3600 * 1000)); // Move to next Monday
            currentSunday = new Date(currentMonday.getTime() + (6 * 24 * 3600 * 1000)); // Move to next Sunday
        }
        
        period++;
    }
    return payPeriods;
}
const getPayPeriodFromDate = (periods: i_period_data[], timestamp: number): i_period_data | null => {
    for (var x : number = 0; x < periods.length; x++) {
        if (timestamp >= periods[x].monday && timestamp <= periods[x].sunday) {
            return periods[x];
        }
    }
    return null; 
}

export { f_empty_promise, delay , f_validate_num , f_validateField , f_format_order , f_timestamp_to_date , f_set_first_letter , f_encode_query_data , countDigits , formatSIN, getPayPeriod, getPayAllPeriods, getPayPeriodFromDate,
   f_return_color , CheckEnv  , f_format_phone_cute , f_make_customer_fd , f_set_local_key , f_get_local_key , f_kill_storage , f_darken_color , f_make_cute_date , f_lighten_hex_color };
