/*
 * Date: 2024
 * Description: Reworked contact addon form.
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useState, useEffect, ReactElement , ChangeEvent } from "react";
import { Box, Button , IconButton, TextField , Table , TableBody , TableCell , TableContainer , TableHead , TableRow , Paper, Grid, Typography , MenuItem } from "@mui/material";

//Interfaces && types
import { i_contact, i_customer_form_props } from "../../../interfaces/customer.interface";

//Middlewares
import { m_validate_contacts } from "../../../validation/main.middleware";
//Icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UpdateIcon from '@mui/icons-material/Update';
//Styles
import { input_base , bottom_line, sub_title } from "../../../styles/form.styles";
import { full_width } from "../../../styles/customer.styles";
import { m_force_str, m_validate_email, m_validate_opt_phone, m_validate_phone } from "../../../validation/utility.middleware";

//Constant
import { empty_contact, provinces } from "../../../utils/constant";

const CustomerContacts = (props: i_customer_form_props) : ReactElement => {

  const C = props.form.contact_array
  //Form
  const [ contact , setContact ] = useState<i_contact>(empty_contact);
  const [ index ,setIndex ] = useState<number>(-1);

  //Errors
  const [ contact_errors , setContactErrors ] = useState<i_contact>({...empty_contact , state : ''});

  const handleDelete = (index: number) => {
   props.setForm({ ...props.form , contact_array : C.filter((_ : i_contact, i : number) => i !== index)});
  };
    const handleContactUpdate = (index: number) => {
        const contact = C[index];
        setContact({...contact})
        setIndex(index);
    }
  const handleUpdate = async () => {
      const [ valid , msg , field ] = m_validate_contacts(contact)
      if(valid){
        if(index !== -1){
          const new_formated = C
          new_formated[index] = contact;
          props.setForm({ ...props.form , contact_array : [ ...new_formated]});
        }else{
         props.setForm({ ...props.form , contact_array : [ ...props.form.contact_array , contact]});
        }          
        handleClear();
      } else {
        setContactErrors({...contact_errors , [field] : msg});
      }
  };
  const handleClear = () => {
    setContact(empty_contact);
    setIndex(-1)
  }
    useEffect(() => {
        const E = contact_errors
        const F = contact
        if (E.first_name !== '' && m_force_str(F.first_name) === '') setContactErrors({...E , first_name : ''});
        if (E.last_name !== '' && m_force_str(F.last_name) === '') setContactErrors({...E , last_name : ''});
        if (E.email !== '' && m_validate_email(F.email)) setContactErrors({...E , email : ''});
        if (E.phone !== '' && m_validate_phone(F.phone)) setContactErrors({...E , phone : ''});
        if (E.mobile_phone !== '' && m_validate_opt_phone(F.mobile_phone))  setContactErrors({...E , mobile_phone : ''});
        if (E.designation !== '' && m_force_str(F.designation) === '') setContactErrors({...E , designation : ''});
    },[contact , contact_errors]);
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setContact({...contact , [name] : value})
    }
  return (
      <Box sx={[ full_width]}>
                <Typography sx={{ marginTop : '2vh' , marginBottom : '2vh', fontSize : 20 , fontWeight : 800}}> Person to contact list</Typography>
        {C.length > 0 ?
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Home phone</TableCell>
                  <TableCell>Mobile phone</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {C?.map((row : i_contact, index : number) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{row.first_name}</TableCell>
                    <TableCell align="right">{row.last_name}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phone}</TableCell>
                    <TableCell align="right">{row.mobile_phone}</TableCell>
                    <TableCell align="right">{row.designation}</TableCell>
                    <TableCell align="right">
                    <IconButton onClick={() => handleContactUpdate(index)}>
                        <UpdateIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(index)}>
                        <HighlightOffIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          : props.view ? 
          <Box sx={{ textAlign : 'center'}}> No contact yet</Box>
          :
           <Box sx={{ textAlign : 'center'}}> No contact yet</Box>
        }
        {props.view === false &&
            <Grid container sx={{ padding : '1em' , border : '1px solid gray' , marginTop : '1vh'}}>
              {index >= 0 &&
              <Grid item xs={12} sx={{ display: 'flex' , justifyContent : 'flex-end'}}>
                <Button sx={{ fontWeight : 800}} variant="contained" color="primary" onClick={() => handleClear()}>Clear</Button>
              </Grid>
              }
                <Grid item xs={3}> <Typography sx={sub_title}>Relationship*</Typography> </Grid>
                <Grid item xs={9}>
                  <TextField sx={[input_base , { width : '300px' }]} name={'designation'} size="small" placeholder="Father , Mother , Brother , Sister" value={contact.designation} onChange={handleChange}
                    error={contact_errors.designation !== '' ? true : false} helperText={contact_errors.designation} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}> <Typography sx={sub_title}>Name*</Typography> </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { width : '300px' }]} name={'first_name'} size="small" placeholder="First name" value={contact.first_name} onChange={handleChange}
                        error={contact_errors.first_name !== '' ? true : false} helperText={contact_errors.first_name} autoComplete={props.dev ? "off" : "new-password" }/>
                    <TextField sx={[input_base , { width : '300px' }]} name={'last_name'} size="small" placeholder="Last name" value={contact.last_name} onChange={handleChange}
                    error={contact_errors.last_name !== '' ? true : false} helperText={contact_errors.last_name} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}> <Typography sx={sub_title}>Email*</Typography> </Grid>
                <Grid item xs={9}>
                  <TextField sx={[input_base , { width : '300px' }]} name={'email'} size="small" value={contact.email} onChange={handleChange}
                      error={contact_errors.email !== '' ? true : false} helperText={contact_errors.email} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}> <Typography sx={sub_title}>Phone number*</Typography> </Grid>
                <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px' }]} name={'phone'} size="small" value={contact.phone} onChange={handleChange}
                    error={contact_errors.phone !== '' ? true : false} helperText={contact_errors.phone} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={3}> <Typography sx={sub_title}>Additional phone number*</Typography> </Grid>
                <Grid item xs={9}>
                <TextField sx={[input_base , { width : '300px' }]} name={'mobile_phone'} size="small" value={contact.mobile_phone} onChange={handleChange}
                    error={contact_errors.mobile_phone !== '' ? true : false} helperText={contact_errors.mobile_phone} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3} > <Typography sx={sub_title}>Unit number / Civic number / Street</Typography> </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base ,{ width : '120px' } ]} name={'unit_number'} size="small" value={contact.unit_number} onChange={handleChange}
                    helperText={contact_errors.unit_number} error={contact_errors.unit_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base ,{ width : '120px' } ]} name={'door_number'} size="small" value={contact.door_number} onChange={handleChange}
                    helperText={contact_errors.door_number} error={contact_errors.door_number !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                     <TextField sx={[input_base ,{ width : '300px' } ]} name={'street'} size="small" value={contact.street} onChange={handleChange}
                    helperText={contact_errors.street} error={contact_errors.street !== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}> <Typography sx={sub_title} >City / Province / Postal code</Typography> </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base ,{ width : '200px' } ]} name={'city'} size="small" value={contact.city} onChange={handleChange}
                    helperText={contact_errors.city} error={contact_errors.city!== ''}autoComplete={props.dev ? "off" : "new-password" }/> 
                    <TextField sx={[input_base ,{ width : '200px' } ]} name={'state'} value={contact.state} size="small" onChange={handleChange}
                    helperText={contact_errors.state} error={contact_errors.state!== ''}autoComplete={props.dev ? "off" : "new-password" } select>
                      {provinces.map((provinces , index ) => (
                            <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>
                      ))}
                    </TextField>
                    <TextField sx={[input_base ,{ width : '140px' } ]} name={"zip"} size="small" inputProps={{ maxLength: 7 }} value={contact.zip} onChange={handleChange}
                    helperText={contact_errors.zip} error={contact_errors.zip!== ''}autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid> 
                <Grid item xs={12} sx={{ display: 'flex' , justifyContent : 'center'}}>
                <Button sx={{ fontWeight : 800}} variant="contained" color="primary" onClick={() => handleUpdate()}> { index >= 0 ? "Update" : "Add to the list"}</Button>
              </Grid>
            </Grid> 
            }
        </Box>
  );
};

export default CustomerContacts
