import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	PDFViewer,
	pdf,
} from "@react-pdf/renderer";
import RepairChoice, { RepairChoiceForm } from "../docs/repair.choice";
import {
	i_customer,
	i_customer_car,
	i_customer_view_props,
	i_insurance,
} from "../../../interfaces/customer.interface";
interface i_document_modal_props extends i_customer_view_props {
	open: boolean;
	onClose: (data: any) => void;
	formError?: string;
	fieldError?:string;
}
const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	height: 1600,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#ffffff",
		padding: 20,
		display: "flex",
		flexDirection: "column",

		// border: "1px solid black",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
		gap: 10,
		justifyContent: "center",
	},
	title: {
		fontSize: 24,
		marginBottom: 10,
	},
	image: {
		width: 500,
		height: 300,
		// display:'block'
	},
});
export const templates = [
	{
		el: RepairChoice,
		name: "repair_choice",
		label: "Confirmation du choix du réparateur",
		form: RepairChoiceForm,
	},
];
const DocumentModal = (props: i_document_modal_props) => {
	const [data, setData] = useState<{ [key: string]: any }>({});
	const handleDataChange = (d: object) => {
		setData({
			...data,
			...d,
            title:docType.name
		});
	};
	const [docType, setDocType] = React.useState<any>(templates[0]);
	const handelCancelClick = () => {
		props.onClose(undefined);
	};
	const handleSubmitClick = () => {
		props.onClose({ ...data, title: docType.name });
	};
	return (
		<Modal
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style} maxHeight={'95vh'} overflow={'auto'}>
				{/* <FormControl fullWidth> */}
				<Typography>Document</Typography>
				<Stack gap={5} marginBottom={3} width={"100%"}>
					<Select
						labelId="document_type"
						id="doc_type"
						value={docType?.name || ""}
						label="Document"
						onChange={(e) => {
							if (!docType || e.target.value != docType.name)
								setDocType(templates.find((el) => el.name == e.target.value));
						}}
					>
						{templates.map((t) => {
							return (
								<MenuItem value={t.name} key={t.name}>
									{t.label}
								</MenuItem>
							);
						})}
					</Select>
					{docType &&
						docType.form({
							customer: props.target,
							customer_cars: props.customer_cars,
							onDataChange: handleDataChange,
							formError:props.formError,
							formField:props.fieldError
						})}
				</Stack>
				<Box marginBottom={3} width={"100%"}></Box>
				{/* </FormControl> */}
				<PDFViewer width={"100%"} height={"800"} showToolbar={false}>
					{docType &&
						docType.el({
							customer: props.target,
							customer_cars: props.customer_cars,
							data
						})}
				</PDFViewer>
				<Stack
					direction={"row"}
					gap={5}
					justifyContent={"flex-end"}
					width={"100%"}
				>
					<Button variant="text" onClick={handelCancelClick}>
						Cancel
					</Button>
					<Button variant="contained" onClick={handleSubmitClick}>
						Submit
					</Button>
				</Stack>
			</Box>
		</Modal>
	);
};

export default DocumentModal;
