
/*
 * Date: 2024
 * Description: Fonction de calcul pour les impots d'une paie pour l'année 2024
 * Author: Philippe Leroux @ Skitsc
 */

//Constants
import { impot_2024 } from "./constant"

const I = impot_2024

//Calcul sans revenues imposables ( QC ) OK
const calcQcTaxes = ( amount : number ) : number => {
    var total = amount
    var sm_tax = 0;
    var md_tax = 0;
    var lg_tax = 0;
    var xl_tax = 0;
    if(amount <= I.qc_taxes.base_amount) return 0
    if(total <= I.qc_taxes.first_bracket){
        sm_tax = ( amount - I.qc_taxes.base_amount ) * I.qc_taxes.first_tax;
    }else{
        sm_tax = ( I.qc_taxes.first_bracket - I.qc_taxes.base_amount ) * I.qc_taxes.first_tax;
        if(total <= I.qc_taxes.second_bracket){
            md_tax = (total - I.qc_taxes.first_bracket) * I.qc_taxes.second_tax;
        }else{
            md_tax = (I.qc_taxes.second_bracket - I.qc_taxes.first_bracket) * I.qc_taxes.second_tax;
            if(total <= I.qc_taxes.third_bracket){
                lg_tax = (total - I.qc_taxes.second_bracket) * I.qc_taxes.third_tax;
            }else{
                lg_tax = (I.qc_taxes.third_bracket - I.qc_taxes.second_bracket) * I.qc_taxes.third_tax;
                xl_tax = (total - I.qc_taxes.third_bracket) * I.qc_taxes.fourth_tax;
            }
        }
    }
    var tax_total = sm_tax + md_tax + lg_tax + xl_tax;
    return tax_total;
}
//Premier calcul de test pour limpot canadien..
const calcFedTaxes = ( amount : number ) : number => {
    var total = amount;
    var sm_tax = 0;
    var md_tax = 0;
    var lg_tax = 0;
    var xl_tax = 0;
    var xll_tax = 0;
    if(amount <= I.fed_taxes.base_amount) return 0
    if(total <= I.fed_taxes.first_bracket){
        sm_tax = ( amount - I.fed_taxes.base_amount ) * I.fed_taxes.first_tax;
    }else{
        sm_tax = ( I.fed_taxes.first_bracket - I.fed_taxes.base_amount ) * I.fed_taxes.first_tax;
        if(total <= I.fed_taxes.second_bracket){
            md_tax = (total - I.fed_taxes.first_bracket) * I.fed_taxes.second_tax;
        }else{
            md_tax = (I.fed_taxes.second_bracket - I.fed_taxes.first_bracket) * I.fed_taxes.second_tax;
            if(total <= I.fed_taxes.third_bracket){
                lg_tax = (total - I.fed_taxes.second_bracket) * I.fed_taxes.third_tax;
            }else{
                lg_tax = (I.fed_taxes.third_bracket - I.fed_taxes.second_bracket) * I.fed_taxes.third_tax;
                if(total <= I.fed_taxes.fourth_bracket){
                    xl_tax = (total - I.fed_taxes.third_bracket) * I.fed_taxes.fourth_tax;
                }else{
                    xl_tax = (I.fed_taxes.fourth_bracket - I.fed_taxes.third_bracket) * I.fed_taxes.fourth_tax;
                    xll_tax = (total - I.fed_taxes.fourth_bracket) * I.fed_taxes.fifth_tax;
                }
            }
        }
    }
    var tax_total = sm_tax + md_tax + lg_tax + xl_tax + xll_tax;
    tax_total = tax_total - ( tax_total * I.fed_taxes.qc_peraquation)
    return tax_total;
}
    //Calcul qui vient prendre en comptes si le montant maximal prévue pour la AE est atteint lors de la génération de la paye
    //De plus sa retourne le total costié selon la periode de la paye
    const cAETaxes = ( G : number , P : number , week : number )  : [ ae_w : number , ae_cumul : number , ae_lord : number]=> {
        const GT = ( G / P ) * week
        const S = G / P
        if(GT >= I.ae.max_amount){
            return [ 0 , I.ae.max_cote , I.ae.lord_cote ]
        }else{
            if(S >= I.ae.max_amount){
                return [ I.ae.max_cote , GT * I.ae.max_cote , I.ae.lord_cote ]
            }else{
                const max_amount = I.ae.max_amount - GT 
                const calc = GT * I.ae.tax_rate
                const lord_calc = GT * I.ae.lord_rate
                if(max_amount < S)
                    return [ max_amount * I.ae.tax_rate , calc + ( max_amount * I.ae.tax_rate ) , lord_calc + ( max_amount * I.ae.lord_rate )]
                else
                return [ S * I.ae.tax_rate , calc + ( S * I.ae.tax_rate ) , lord_calc + ( S * I.ae.lord_rate ) ]
            }
        }
    }
    //Calcul qui vient prendre en comptes si le montant maximal prévue pour la RQAP est atteint lors de la génération de la paye
    //De plus sa retourne le total costié selon la periode de la paye
    const cRQAPTaxes = ( G : number , P : number , week : number ) : [ rqap_w : number , rqap_cumul : number , rqap_lord : number] => {
        const GT = ( G / P ) * week
        const S = G / P
        if(GT >= I.rqap.max_amount){
            return [ 0 , I.rqap.max_cote , I.rqap.max_cote_lord ]
        }else{
            if(S >= I.rqap.max_amount){
                return [ I.rqap.max_cote , I.rqap.max_cote , I.rqap.max_cote_lord ]
            }else{
                const max_amount = I.rqap.max_amount - GT 
                const calc = GT * I.rqap.tax_rate
                const lord_calc = GT * I.rqap.lord_rate 
                if(max_amount < S)
                    return [ max_amount * I.rqap.tax_rate , calc + ( max_amount * I.rqap.tax_rate ) , lord_calc + ( max_amount * I.rqap.lord_rate ) ]
                else
                return [ S * I.rqap.tax_rate , calc + ( S * I.rqap.tax_rate ) , lord_calc + ( S * I.rqap.lord_rate ) ]
            }
        }
    }
    //Calcul pour une semaine visé de la rrq ( Seulement le premié régime.)
    const CalcWeekRRQ = ( G : number , P : number , week : number ) : number => {
        const GT = ( G / P ) * week
        const S = G / P
        const PM = 12
        var SMAX = S
        const L = I.rrq.max_amount - GT 
        var C1 = I.rrq.max_amount * ( PM / 12 ) - GT
        C1 = C1 < 0 ? 0 : C1
        if(L < SMAX){
            SMAX = L
        }
        var C2 = I.rrq.tax_rate * ( SMAX - ( I.rrq.base_amount / P))
        C2 = C2 < 0 ? 0 : C2
        const RRQ1 = C1 > C2 ? C2 : C1
        return RRQ1
    }

    const cRRQTaxes = ( G : number , P : number , week : number ) : [ rrq_w : number , rrq2_w : number , rrq_cumul : number , rrq_fed : number] => {
        //Total Brute avant paye
        const GT = ( G / P ) * week
        //Total d'une paye brute
        const S = G / P
        //Nombre de mois de la periode de paye ( total ) * On pourrais calc a partir du mois specifique ce qui change le calcule
        const PM = 12
        //Ici on calcul la portion RRQ a payé pour la periode de paye ( basé sur le brute , Nombre de paye et la periode de paye en cours)
        var RRQ1 = CalcWeekRRQ( G, P, week )
        //Variable pour calculé le cumuls de RRQ ( Incluant aussi la supplémentaire si il est cotisé)
        var total : number = 0;

        //Variable pour le federal .
        var total_fed = 0 ;
        // Avec ces boucles on vien calculé le cumul selon le nombre de paye produite( On pourrait aussi utilisé les payes deja sauvegardé anterieurement. )
        // C'est vraiment pour démontré que le calcul fonctionne correctement
        for(var i = 0 ; i < ( week + 1 ) ; i++) total += CalcWeekRRQ( G, P, i)
        // Ici on vien s'assuré de ne pas dépassé le montant maximal autorisé pour la RRQ du premier palié
        // Il Faut comprendre que thecniquement les premier 3500 dollars de l'annés ont n'est pas sensé cotisé 
        // de plus le montant maximal autorisé pour la RRQ du premier palié est de 68500 dollars -> 4160$
        // Donc si on paye de la rrq depuis la premiere paye ( week 1 ) bien on pourrait arrivé a 4160 de cotisation avant le 68500 de revenue.
        // Voila pourquoi la condition pour ne pas over cotisé. J'ai testé avec le WEBRAS de revenue quebec
        if(total >= I.rrq.max_cote){
            RRQ1 = RRQ1 - ( total - I.rrq.max_cote ) > 0 ? ( total - I.rrq.max_cote ) : 0
            total = I.rrq.max_cote
        }
        var RRQ2 = 0
        var RRQ2_retain = 0;
        // Ici on vien testé pour le régime complémentaire de la RRQ
        if(( GT + S ) > I.rrq.max_amount){
            // Ici je vien calculé si on n'a deja cumulé pour le regime complémentaire 68500$ -> 73200$
            if(( GT - I.rrq.max_amount ) * I.rrq.tax_rate_two > 0 ){
                RRQ2_retain = ( GT - I.rrq.max_amount ) * I.rrq.tax_rate_two
            }
            var amount = 0;
            //Ici on calcul combien de salaire illigible pour le regime complémentaire
            if( (GT + S) > I.rrq.max_amount_two){
                amount = ( GT + S ) - I.rrq.max_amount_two
                amount = S - amount
            }else{
                amount = ( GT + S ) - I.rrq.max_amount
            }
            //Ici c'est les calcul quon retrouve dans le guide de revenue quebec pour calculé le regime complémentaire
            const W = GT < I.rrq.max_amount * ( PM / 12 ) ? GT : I.rrq.max_amount * ( PM / 12 ) 
            var c3 = ( I.rrq.max_cote_two - I.rrq.max_cote ) * ( PM / 12 ) - RRQ2_retain 
            // Si négatife bien c'est 0
            c3 = c3 < 0 ? 0 : c3
            var c4 = I.rrq.tax_rate_two * ( GT + amount - W)
            // Meme chose ici
            c4 = c4 < 0 ? 0 : c4
            RRQ2 = c3 < c4 ? c3 : c4
            if((GT + S) > I.rrq.max_amount_two){
                //Dans le cas que notre brute total depasse la maximal autorisé pour le regime complémentaire on sort le chiffre maximum
                total = I.rrq.max_cote_two 
            }else{
                //Sinon on vien ajouté le total antérieur le regime premié + les cotisations au deuxieme regime si il ya lieu, + la cotisation pour la présente paye.
                 total = total + RRQ2_retain + RRQ2
            }

        }else{
        
        }
        return [ RRQ1 , RRQ2 , total , total_fed ]
    }

const customCeil = (value : number) : number=> {
    const integerPart = Math.floor(value);
    const decimalPart = value - integerPart;
    if (decimalPart >= 0.5) {
        return integerPart + 1;
    } else {
        return integerPart;
    }
};
const rTTD = ( num: number ) : number  => {
    return Math.round(num * 100) / 100;
}
//Calcul simple pour simulé le total payé de rqap pour l'année
const calcRQAPtaxes = ( amount : number ) : number => {
    if(amount >= I.rqap.max_amount){
        return I.rqap.max_amount * I.rqap.tax_rate
    }else{
        return amount * I.rqap.tax_rate
    }
}
//Calcul simple pour simulé le total payé de AE pour l'année
const calcAeTaxes = ( amount : number ) : number => {
    if(amount * I.ae.tax_rate >= I.ae.max_cote) {
        return I.ae.max_cote;
    }else{
        return amount * I.ae.tax_rate;
    }
}
    //Calcul simple pour simulé le total payé de RRQ pour l'année
const calcRRQtaxes = ( amount : number ) : [ c : number , C : number] => {
    if(I.rrq.max_amount <= amount){
        var sup_amount = amount >= I.rrq.max_amount_two ? I.rrq.max_cote_two - I.rrq.max_cote :  ( ( amount - I.rrq.max_amount )* I.rrq.tax_rate_two )
        return [ I.rrq.max_cote , sup_amount ]
    }else{
        if(amount < I.rrq.base_amount){
            return  [ 0 , 0 ]
        }else{
            return [( amount - I.rrq.base_amount ) * I.rrq.tax_rate , 0 ]
        }
    }
}
const calcQcTaxable = ( amount : number , pay_count : number , week : number , reer : number) : number => {
    //Nombre de payes dans l'année
    const P : number = pay_count
    //Total brute
    const G : number = amount

    //Total Brute hebdomadaire
    const S = G / pay_count

    //Cotisations ( RPA , REER , RVER , RPAC , CELIAPP , RIC , Actions ,...)
    var F : number = reer
    // Credits pour emplois (QC)
    const CD :  number  = 0.06 * ( amount )
    const H  : number = CD >= I.qc_taxes.work_credit ? I.qc_taxes.work_credit : CD

    //Deductions pour pensions alimentaires qui nes pas fiscalisé formulaire TP-1015.3
    const J= 0;
    //Voir TP-1016 Demande de reductions de retenues dimpots.
    const J1 = 0;
    //gratifications, paiements rétroactifs ou autres paiements forfaitaires semblables versés depuis le début de l’année, excepté ceux versés pendant la période de paie

    const B1 = 0
    //gratifications, paiements rétroactifs ou autres paiements forfaitaires semblables versés pendant la période de paie
    const B2 = 0
    //Total des 2 taxes rrq

    const [ C1 , C2 ] = cRRQTaxes(G,P,week)
    // //Constante pour le taux d'imposition
    // var k = 2589
    // //
    // var K = 0;
    // //
    // var q = 0;
    // //
    // var Q = 0;
    //Valeur de costisations pour la rrq compris dans le calcul du revenue imposable.
    const csa = ( C1 ) * (0.01 / 0.0640) + ( C2 )
    //
    const RI = P * ( S - F - ( H / pay_count ) - csa ) - J - J1
    //
    return RI
}
const getCK = ( amount : number ) : [ K : number , R : number] => {
    var K = 0
    var R = 0
    R = amount > I.fed_taxes_old.base_amount ? I.fed_taxes_old.first_tax : R
    R = amount > I.fed_taxes_old.first_bracket ? I.fed_taxes_old.second_tax : R
    R = amount > I.fed_taxes_old.second_bracket ? I.fed_taxes_old.third_tax : R
    R = amount > I.fed_taxes_old.third_bracket ? I.fed_taxes_old.fourth_tax : R


    K = amount > I.fed_taxes.first_bracket ? I.fed_taxes.first_cap : K
    K = amount > I.fed_taxes.second_bracket ? I.fed_taxes.second_cap : K
    K = amount > I.fed_taxes.third_bracket ? I.fed_taxes.third_cap : K
    K = amount > I.fed_taxes.fourth_bracket ? I.fed_taxes.fourth_cap : K

    return [ K , R ]
}
const calcQcTaxableFed = ( amount : number , pay_count : number , week : number , reer : number , syndical : number ) : number => {
       
    const X = I.fed_taxes_old.first_tax 
    //Nombre de paye Ok
    const p = pay_count
    //Revenue brute Ok
    const i = amount / p
    //Reer autres ok
    const f = reer
    //Calcul rrq Ok
    const [ c , C  ] = cRRQTaxes(amount , pay_count , week) 
    //Paiement de Pension alimentaire ok
    const f2 = 0
    //Deductions pour regime complementaire de paye ok , on prend en compte aussi le cotisations sumplémentaire de RRQ
    const f5q = ( c ) * (0.01 / I.rrq.tax_rate) + ( C )
    //Deductions syndicats ok 
    const u1 = syndical
    // Deductions frais de gardes. ok
    const f1 = 0
    // Deductions annuelles pour resident visée dune region specifique ok
    const hd = 0
    //Autre credit dimpot , frais medicaux , dons ok       

    const CalcFedRRQ = ( G : number  ) => {
        var total = ( G - 3500 ) * I.rrq.tax_rate_base
        total = total >= 3510 ? 3510 : total
        return total
    }
    //Démarche pour le calcul proposé dans le guide de revenue canada ( ARC )
    // //Calcul AE Ok
    //const pm = 12
    // const [ ae , c_ae ] = cAETaxes(amount , pay_count , week) 
    // //Calcul rqap Ok
    // const [ rqap , c_rqap ] = cRQAPTaxes(amount , pay_count , week) 
    // const m1 = ( X * ( p *  c  * ( 0.0540 / I.rrq.tax_rate ) * (pm / 12))) > 3510 ? 3510 : X * ( p *  c * ( 0.0540 / I.rrq.tax_rate ) * (pm / 12))  //maximum 3510.00
    // const m2 = ( X * ( p * ae)) > 834.24 ?  834.24 : ( X * ( p * ae)) // max 834.24
    // const m3 = ( X * ( p * rqap * I.rqap.tax_rate)) > 464.36 ? 464.36 :   X * ( p * rqap * I.rqap.tax_rate )  //maximum 464.36 $
    //Credit dimpot pour le montant de base avec le plus bas taux dimposition OK!
    // const k1 = X * I.fed_taxes.base_amount
    // const k2q = m1 + m2 + m3
    // const k3 = 0
    // // Credit d'impot fédéral montant canadien pour emplois
    // const k4 = X * I.fed_taxes.CCE
            //Autres benifices ( genre achat dactions ou placement )
    // const lcf = 0
    //Impot federal de base
    // var t3 = ( R * RI ) - K - k1 - k2q - k3 - k4
    // t3 = t3 >= 0 ? t3 : 0
    // //Calcul pour limpot final a payer
    // var t1 = (t3 - (p * lcf)) - (I.fed_taxes_old.qc_peraquation * t3)

    // J'ai finalement trouvé le bon calcul qui me donne les memes valeurs que le calculateur en ligne. https://www.canada.ca/en/revenue-agency/services/forms-publications/payroll/t4032-payroll-deductions-tables/t4032qc-jan/t4032qc-january-general-information.html
    // On pourrait facilement ajouté toute les valeurs optionelles , REER etc..
    // Revenue imposable ok
    var RI =  p * ( i - f - f2 - f5q - u1 ) - hd - f1// CA CEST OK
    RI = RI / 52
    RI = rTTD(RI) * 52
    const credits = ( I.fed_taxes.base_amount + CalcFedRRQ(amount) + calcAeTaxes(amount) + calcRQAPtaxes(amount) + I.fed_taxes.CCE ) * X
    //Constante pour le premier palier dimposition !OK
    const [ K , R ] = getCK(RI)
    const sub_total = ( RI * R ) - credits - K
    var final_total = rTTD(sub_total - (I.fed_taxes.qc_peraquation * sub_total))
    final_total = final_total > 0 ? final_total : 0
    return final_total
}


    // //Methode cumulative... pour limpot fed Essaie..
    // const CCRRQ = ( amount : number , pay_count : number , week : number ) => {
    //     var t1 = 3510  * (12/12)
    //     const S = amount / pay_count
    //     const [ c1 , c2 ]= cRRQTaxes(amount , pay_count , week)
    //     const c_total = ( S * I.rrq.tax_rate ) * ( week ) 
    //     var t2 = (c_total * (0.0540/0.0640)) + ((week) * (c1 + c2) * (0.0540/0.0640))
    //     return t1 <= t2 ? t1 : t2
    // }
    // const CCAE = ( amount : number , pay_count : number , week : number ) => {
    //     const t1 = 834.24
    //     const S = amount / pay_count
    //     const ae_total = ( S * I.ae.tax_rate ) * ( week ) 
    //     const ae = cAETaxes(amount , pay_count , week)
    //     const t2 = ae_total + ( week * ae)
    //     console.log(t1 , t2)
    //     return t1 <= t2 ? t1 : t2
    // }
    // const CCRQAP = ( amount : number , pay_count : number , week : number ) => {
    //     const t1 = 436.36
    //     const S = amount / pay_count
    //     const rqap_total = ( S * I.rqap.tax_rate ) * ( week ) 
    //     const rqap = cAETaxes(amount , pay_count , week)
    //     const t2 = rqap_total + ( week * rqap * 0.00494)
    //     console.log(t1 , t2)
    //     return t1 <= t2 ? t1 : t2
    // }

export { calcQcTaxable , getCK , customCeil , calcAeTaxes , calcRRQtaxes , calcRQAPtaxes , cRRQTaxes , rTTD , calcQcTaxes , calcFedTaxes , cAETaxes , cRQAPTaxes , calcQcTaxableFed } 