

/*
 * Date: 2024
 * Description: Payroll inputs ( super calcs)
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { useState } from "react";
import { Box , Grid , Typography , IconButton , TextField , MenuItem, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { ReactElement , ChangeEvent, useEffect } from "react"

//Components
//Interfaces && types

//Constants 
import { pay_periods } from "../../../utils/constant";
//Icons
import { ArrowBack } from "@mui/icons-material";

//Styles
import { sub_title , input_base , bottom_line } from "../../../styles/form.styles";
import { i_payroll_input_props } from "../../../interfaces/payroll.interface";


const PayrollInputs = ( props : i_payroll_input_props ) : ReactElement=> {
    const F = props.form;
    const [ week , setWeek ] = useState<number>(0)
    const [ pay_count] = useState<number>(26)

    useEffect(() => {
        
    },[F.hours, F.rate , week])

    const handleChangeWeek = ( e :ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setWeek(Number(value))
    }
    const bi_weekly_period = pay_periods.slice(Math.ceil(pay_periods.length / 2))
  return (
    <Box p={2} sx={{ marginBottom : '1vh' }} id={'task_inputs'} >
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display : 'flex' }}>
                    <IconButton sx={{ marginRight : '4px'}} onClick={() => props.onClose('table')}><ArrowBack color={'primary'} sx={{ marginRight : 'auto'}}/></IconButton>
                    <Typography sx={{ fontWeight : 800 , fontSize : 20 ,  alignSelf : 'center'}}>{F._id !== '' ? "Edit pay "+ props.title : "Add a pay"}</Typography>
                </Box>
                <Typography sx={{ fontWeight : 800 , fontSize : 16 , marginBottom : '2vh' , color : 'grey'}}>Enter your pay informations</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Select a pay period ( 2024 )</Typography>
            </Grid>
            <Grid item xs={9}>

                <TextField sx={[input_base , { width : '300px'} ]}  size="small" select value={week} onChange={handleChangeWeek} autoComplete={props.dev ? "off" : "new-password" }>
                    {pay_count === 26 ? bi_weekly_period.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option + 1}
                    </MenuItem>
                )) :
                    pay_periods.map((option) => (
                    <MenuItem key={option} value={option}>
                            {option + 1}
                        </MenuItem>
                    ))}
                </TextField>
      
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>

            <Grid item xs={3}>
                <Typography sx={sub_title}>Select employee's *</Typography>
            </Grid>
            
            <Grid item xs={9}>
                Here the logics to display all the employees with their timesheet's
                Here we could select with checkboxes wich employees we want to display their pay slip
                And also with selection make pays ?
                Maybe display the cost to the employer ?
                and the cumulative
            </Grid>
           
        </Grid>

        <hr style={bottom_line}/>
        <Grid container sx={{ marginTop : '1vh' , marginBottom : '1vh'}}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
                <Button onClick={() => props.onClose('table')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Cancel</Button>
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Submit</LoadingButton>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}

export default PayrollInputs