


/*
 * Date: 2024
 * Description: Middleware for planner
 * Author: Philippe Leroux @ Skitsc
 */

//Interfaces
import { i_user , i_user_login, i_user_reset , i_user_rules } from "../interfaces/user.interface";
import { t_method } from "../types/types";
import { i_job , i_job_rules , t_job_fields } from "../interfaces/jobs.interface";
import { i_cal_event, i_planner_rules} from "../interfaces/planner.interface";
import { i_customer_rules , i_customer, i_contacts_rules, i_contact, i_customer_car_rules, t_customer_car_valid_type, i_customer_car_registration_rules,  i_customer_car_insurance_rules, i_customer_car, i_insurance, i_registration, i_customer_document, i_customer_document_rules, i_claim_pdf, i_claim_rules, t_claim_fields} from "../interfaces/customer.interface";
//Constants
import { lexique_message } from "../utils/constant";

//Middlewares
import { m_validate_email , m_validate_password , m_validate_empty_fields, m_validate_str,  m_validate_colors ,  m_validate_phone, m_validate_opt_phone, m_validate_customer_type, m_validate_customer_title , m_validate_tax_rates , m_validate_tax_type , m_validate_currency ,
   m_validate_payment_condition , m_validate_language , m_validate_zip_code , m_validate_contact_arr, m_validate_opt_zip_code, m_validate_number, m_validate_email_opt, m_validate_hex_colors, m_validate_url_opt, m_validate_task_state, m_validate_task_type, m_validate_select,
    m_validate_opt_nas, m_validate_user_type, m_validate_skill_code, m_validate_password_opt} from "./utility.middleware"; import { i_app_config, i_app_config_rules } from "../interfaces/setting.interface";
import { i_task, i_task_rules, t_task_fields } from "../interfaces/task.interface";

const m_validate_login = ( form : i_user_login , display : 'login' | 'reset' | 'recovery' | 'final' | 'register' ) : [ boolean , string , string ] => {
  if(!m_validate_email(form.email)) return [ false, lexique_message.email_error, 'email'  ];
  if(display === 'login' && !m_validate_password(form.password)) return [ false, lexique_message.password_error , 'password'];
  return [ true , '' , ''  ]
}
const m_validate_recovery_reset = ( form : i_user_reset ) : [ boolean , string , string ] => {
  if(!m_validate_password(form.password)) return [ false, lexique_message.password_error, 'password'  ];
  if(form.password !== form.confirm_password) return [ false, 'Passwords do not match', 'confirm_password'  ];
  return [ true , '' , ''  ]
}
const m_validate_planner = ( form : i_cal_event ) : [ boolean , string , string ] => {
  const validations_rules : i_planner_rules[] = [
      { field: 'title', validate: m_validate_empty_fields, error: lexique_message.empty_error },
      { field: 'title', validate: m_validate_str, error: lexique_message.str_error },
      { field: 'description', validate: m_validate_str, error: lexique_message.str_error },
      { field: 'start', validate: m_validate_number, error: lexique_message.date_error },
      { field: 'end', validate: m_validate_number, error: lexique_message.date_error },
      { field: 'color', validate: m_validate_colors, error: lexique_message.empty_error }
  ];
  for (const validation of validations_rules) {
      const { field, validate, error } = validation;
      if (!validate((form as i_cal_event)[field])) {
        return [false, error, field];
      }
    }
  return [ true , '' , ''  ]
}
const m_validate_user = ( form : i_user , method : t_method ) : [ boolean , string , string ] => {
  const validation_rules : i_user_rules[] = [
    { field : 'first_name', validate : m_validate_empty_fields , error : lexique_message.empty_error },
    { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
    { field : "nas" , validate : m_validate_opt_nas , error : lexique_message.nas_error },
    { field : 'phone_one', validate : m_validate_phone, error : lexique_message.phone_error },
    { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : 'password' , validate : m_validate_password_opt, error : lexique_message.password_error },
    { field : 'confirm_password' , validate : m_validate_password_opt, error : lexique_message.password_error},
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    { field : "type" , validate: m_validate_user_type , error : lexique_message.user_type_error},
    { field : "skill_code" , validate : m_validate_skill_code , error : lexique_message.skill_code_error},
    { field : "hour_rate" , validate : m_validate_number, error : lexique_message.numeric_error},
    { field : "start" , validate : m_validate_number , error : lexique_message.numeric_error},
    { field : "end" , validate : m_validate_number , error : lexique_message.numeric_error},
    { field : "code" , validate : m_validate_number, error : lexique_message.numeric_error},
    { field : "nip" , validate : m_validate_number, error : lexique_message.numeric_error},
  ]
  for (const validation of validation_rules) {
    const { field, validate, error } = validation;
    if(!validate((form as i_user)[field]))  return [false, error, field];
  }
  if(form.phone_two !== undefined && form.phone_two !== '') if(m_validate_phone(form.phone_two)) return [ false, lexique_message.phone_error, 'phone_two' ];
  if(method === 'POST'){
      if(!m_validate_password(form.password )) return [ false, lexique_message.password_error , 'password' ];
      if(form.confirm_password !== undefined && !m_validate_password(form.confirm_password )) return [ false, lexique_message.password_error , 'confirm_password' ];
      if(form.password !== form.confirm_password) return [ false, lexique_message.match_error, 'confirm_password' ];
  }
  return [ true , '' , ''  ]
}
const m_validate_profil_information = ( form : i_user ) : [ boolean , string , string ] => {
    const validation_rules : i_user_rules[] = [
      { field : 'first_name', validate : m_validate_empty_fields , error : lexique_message.empty_error },
      { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
      { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
      { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
      { field : 'phone_one', validate : m_validate_phone, error : lexique_message.phone_error },
      { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error },
      { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
      { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
      { field : "street", validate : m_validate_str, error : lexique_message.special_error },
      { field : "city", validate : m_validate_str, error : lexique_message.special_error },
      { field : "state", validate : m_validate_str, error : lexique_message.special_error },
      { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    ]
    for (const validation of validation_rules) {
      const { field, validate, error } = validation;
      if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    if(form.phone_two !== undefined && form.phone_two !== '') if(m_validate_phone(form.phone_two)) return [ false, lexique_message.phone_error, 'phone_two' ];
    return [ true , '' , ''  ]
}
const m_validate_profil_passwords = ( form : i_user ) : [ boolean , string , string ] => {
    const validation_rules : i_user_rules[] = [
        { field : 'password' , validate : m_validate_password, error : lexique_message.password_error },
        { field : 'confirm_password' , validate : m_validate_password, error : lexique_message.password_error},
        { field : 'old_password' , validate : m_validate_password, error : lexique_message.password_error}
    ]
    for (const validation of validation_rules) {
    const { field, validate, error } = validation;
    if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    if(form.old_password !== undefined && form.old_password !== form.password) return [ true, "Password does not match..", 'confirm_password' ];
    return [ true , '' , ''  ]
}
const m_validate_uploads = ( uploads : any[] , assets : string[] ) : [ boolean , string , string ] => {
    //Some validations at another level here
    return [ true , '' , ''  ]
}
const m_validate_app_config = ( form : i_app_config ) : [ boolean, string, string ] => {
    const validation_rules : i_app_config_rules[] = [
        { field : 'name' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'name' , validate : m_validate_str, error : lexique_message.special_error },
        { field : 'email' , validate : m_validate_email_opt, error : lexique_message.email_error },
        { field : 'url', validate : m_validate_url_opt, error : lexique_message.url_error},
        { field : 'id' , validate : m_validate_str, error : lexique_message.special_error },
        { field : 'country', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'country', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'activity', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'activity', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'phone' , validate : m_validate_opt_phone, error : lexique_message.phone_error },
        { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error},
        { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "street", validate : m_validate_str, error : lexique_message.special_error },
        { field : "city", validate : m_validate_str, error : lexique_message.special_error },
        { field : "state", validate : m_validate_str, error : lexique_message.special_error },
        { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
        { field : "primary_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
        { field : "secondary_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
        { field : "selected_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
        { field : "hover_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
    ]
    for (const validation of validation_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_app_config)[field]))  return [false, error, field];
    }
    return [ true , '', '' ]
}
const m_validate_contacts = ( form : i_contact ) : [ boolean, string , string ] => {
    const validation_rules : i_contacts_rules[] = [
        { field : 'designation', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'designation', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'first_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
        { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
        { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
        { field : 'phone', validate : m_validate_phone, error : lexique_message.phone_error },
        { field : 'mobile_phone' , validate : m_validate_opt_phone, error : lexique_message.phone_error },
        { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
        { field : "street", validate : m_validate_str, error : lexique_message.special_error },
        { field : "city", validate : m_validate_str, error : lexique_message.special_error },
        { field : "state", validate : m_validate_str, error : lexique_message.special_error },
        { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    ]
    for (const validation of validation_rules) {
        const { field, validate, error } = validation;
        if(!validate((form)[field]))  return [false, error, field];
    }
  return [ true , '' , '' ]
}
const m_validate_customer = ( form : i_customer, method : t_method ) : [ boolean, string, string ] => {
  const validation_rules_completed : i_customer_rules[] = [
    { field : "first_name", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "first_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "middle_name", validate : m_validate_str, error : lexique_message.special_error},
    { field : "last_name", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "last_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "customer_type" , validate : m_validate_customer_type, error : lexique_message.customer_type_error },
    { field : "email", validate : m_validate_email, error : lexique_message.email_error },
    { field : "title", validate : m_validate_customer_title, error : lexique_message.customer_title_error },
    { field : "phone_one", validate : m_validate_phone, error : lexique_message.phone_error },
    { field : "phone_two", validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : "company_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "tax_preference", validate : m_validate_tax_type, error : lexique_message.tax_type_error },
    { field : "tax_rate", validate : m_validate_tax_rates, error : lexique_message.tax_rates_error },
    { field : "currency", validate : m_validate_currency, error : lexique_message.currency_error },
    { field : "payment_terms", validate : m_validate_payment_condition, error : lexique_message.payment_terms_error },
    { field : "portal_language", validate : m_validate_language, error : lexique_message.portal_language_error },
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number" , validate : m_validate_empty_fields , error : lexique_message.empty_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_zip_code, error : lexique_message.zip_error },
    { field : "contact_array", validate : m_validate_contact_arr , error : lexique_message.contact_arr_error },
    { field : "welcome_email", validate : m_validate_str, error : lexique_message.empty_error },
    { field : "notes" , validate : m_validate_str, error : lexique_message.special_error}
  ]
  const validation_rules : i_customer_rules[] = [
    { field : "first_name", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "first_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "middle_name", validate : m_validate_str, error : lexique_message.special_error},
    { field : "last_name", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "last_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "customer_type" , validate : m_validate_customer_type, error : lexique_message.customer_type_error },
    { field : "email", validate : m_validate_email, error : lexique_message.email_error },
    { field : "title", validate : m_validate_customer_title, error : lexique_message.customer_title_error },
    { field : "phone_one", validate : m_validate_phone, error : lexique_message.phone_error },
    { field : "phone_two", validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : "company_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "tax_preference", validate : m_validate_tax_type, error : lexique_message.tax_type_error },
    { field : "tax_rate", validate : m_validate_tax_rates, error : lexique_message.tax_rates_error },
    { field : "currency", validate : m_validate_currency, error : lexique_message.currency_error },
    { field : "payment_terms", validate : m_validate_payment_condition, error : lexique_message.payment_terms_error },
    { field : "portal_language", validate : m_validate_language, error : lexique_message.portal_language_error },
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    { field : "contact_array", validate : m_validate_contact_arr , error : lexique_message.contact_arr_error },
    { field : "welcome_email", validate : m_validate_str, error : lexique_message.empty_error },
    { field : "notes" , validate : m_validate_str, error : lexique_message.special_error}
  ]
  if(form.complete){
    for (const validation of validation_rules_completed) {
      const { field, validate, error } = validation;
      
        if(!validate((form as i_customer)[field]))  return [false, error, field];
        if(field === "zip"){
            //Licences validation here...
        }
    }
  }else{
    for (const validation of validation_rules) {
      const { field, validate, error } = validation;
      if(!validate((form as i_customer)[field]))  return [false, error, field];
    }
  }

    return [ true , '' , '' ]
  }
const m_validate_customer_document = ( form : i_customer_document, method : t_method ) : [ boolean, string, string ] => {
  const validation_rules_completed : i_customer_document_rules[] = [
    {
      field: "signature",
      validate: m_validate_empty_fields,
      error: lexique_message.empty_error,
    },
  ]
  const validation_rules: i_customer_document_rules[] = [
    { field : "title", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "title", validate : m_validate_str, error : lexique_message.str_error},
    { field : "customer_id", validate : m_validate_empty_fields, error : lexique_message.empty_error},
	];
  if(form.signature){
    for (const validation of validation_rules_completed) {
      const { field, validate, error } = validation;
      if(!validate((form as i_customer_document)[field]))  return [false, error, field];
    }
  }else{
    for (const validation of validation_rules) {
      const { field, validate, error } = validation;
      if (!validate((form as i_customer_document)[field]))
				return [false, error, field];
    }
  }

    return [ true , '' , '' ]
  }
  const m_validate_customer_car = ( form : any , part : t_customer_car_valid_type) : [ boolean, string, string ] => {
    const validation_rules_car : i_customer_car_rules[] = [
      { field : "year", validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : "year", validate : m_validate_number , error : lexique_message.numeric_error},
      { field : "make", validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : "make", validate : m_validate_str, error : lexique_message.special_error},
      { field : "model", validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : "model", validate : m_validate_str, error : lexique_message.special_error },
      { field : "trim", validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : "trim", validate : m_validate_str, error : lexique_message.special_error},
      { field : "color", validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field : "color", validate : m_validate_str, error : lexique_message.special_error },
      { field : "color_code", validate : m_validate_str, error : lexique_message.special_error },
      { field : "color_code_two", validate : m_validate_str, error : lexique_message.special_error },
      { field : "kilo", validate : m_validate_str, error : lexique_message.special_error},
      { field : "vin", validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : "vin", validate : m_validate_str, error : lexique_message.special_error },
    ]
    const validations_rules_registration : i_customer_car_registration_rules[] = [
      { field : 'registration_number', validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field : 'registration_number', validate : m_validate_str, error : lexique_message.str_error },
      { field : 'no_cert' , validate : m_validate_str, error : lexique_message.str_error},
      { field : 'delivered' , validate : m_validate_number , error : lexique_message.date_error},
      { field : 'expires' , validate : m_validate_number, error : lexique_message.date_error},
    ]
    const validations_rules_insurances : i_customer_car_insurance_rules[] = [
      { field : 'company_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field : 'company_name', validate : m_validate_str, error : lexique_message.str_error },
      { field : 'no_police' , validate : m_validate_str, error : lexique_message.str_error},
      { field : 'no_police' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field : 'courtier' , validate : m_validate_str, error : lexique_message.str_error},
      { field : 'new_value_code' , validate : m_validate_str, error : lexique_message.str_error},
      { field : 'delivered' , validate : m_validate_number , error : lexique_message.date_error},
      { field : 'expires' , validate : m_validate_number, error : lexique_message.date_error},
    ]
    if(part === 'car'){
      for (const validation of validation_rules_car) {
        const { field, validate, error } = validation;
        if(!validate((form as i_customer_car)[field]))  return [false, error, field];
      }
    }
    if(part === 'registration'){
      for (const validation of validations_rules_registration) {
        const { field, validate, error } = validation;
        if(!validate((form as i_registration)[field]))  return [false, error, field];
      }
    }
    if(part === 'insurance'){
      for (const validation of validations_rules_insurances) {
        const { field, validate, error } = validation;
        if(!validate((form as i_insurance)[field]))  return [false, error, field];
      }
    }
    return [true, '', ''];
  }
  const m_validate_job = ( form : i_job ) : [ boolean, string, string ] => {
    const validation_rules : i_job_rules[] = [
        {field : "title", validate : m_validate_empty_fields, error : lexique_message.empty_error},
        {field : "title", validate : m_validate_str, error : lexique_message.special_error },        
        {field : "order_number" , validate : m_validate_empty_fields, error : lexique_message.empty_error },
        {field : "order_number", validate : m_validate_str, error : lexique_message.special_error },
        {field : "claim_number" , validate : m_validate_str , error : lexique_message.special_error} ,
        {field : "user_id", validate : m_validate_select, error : lexique_message.select_error},
        {field : "description", validate : m_validate_str, error : lexique_message.special_error },
        {field : "customer_id", validate : m_validate_select, error : lexique_message.select_error},
        {field : "car_id", validate : m_validate_select, error : lexique_message.select_error},
        {field : "status", validate : m_validate_select, error : lexique_message.select_error}
    ]
    const f_check_rows = ( body : i_job ) : [ boolean , string , t_job_fields] => {
        for (const validation of  validation_rules) {
            const { field, validate, error } = validation;
            if (!validate((body)[field])) return [false, error, field];
        }
        return [ true , '' , '_id']
    }
    return f_check_rows(form);
  }
  const m_validate_task = ( form : i_task ) : [ boolean, string, string ] => {
    const validation_rules : i_task_rules[] = [
        {field : "title", validate : m_validate_empty_fields, error : lexique_message.empty_error},
        {field : "title", validate : m_validate_str, error : lexique_message.special_error },
        {field : "time_estimated", validate : m_validate_empty_fields, error : lexique_message.empty_error},
        {field : "time_estimated", validate : m_validate_number, error : lexique_message.numeric_error },
        {field : "user_id", validate : m_validate_select, error : lexique_message.empty_error},
        {field : "type", validate : m_validate_task_type, error : lexique_message.select_error},
        {field : "start", validate : m_validate_empty_fields, error : lexique_message.empty_error},
        {field : "end", validate : m_validate_empty_fields, error : lexique_message.empty_error},
        {field : "description", validate : m_validate_str, error : lexique_message.special_error },
        {field : "note", validate : m_validate_str, error : lexique_message.special_error },
        {field : "status", validate : m_validate_task_state, error : lexique_message.select_error},
        {field : "job_id" , validate : m_validate_empty_fields, error : lexique_message.empty_error },
        {field : 'index' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
        {field : 'index' , validate : m_validate_number, error : lexique_message.numeric_error}
    ]
    const f_check_rows = ( body : i_task ) : [ boolean , string , t_task_fields] => {
        for (const validation of  validation_rules) {
            const { field, validate, error } = validation;
            if (!validate((body)[field])) return [false, error, field];
        }
        return [ true , '' , '_id']
    }
    return f_check_rows(form);
  }
  const m_validate_claim = ( form : i_claim_pdf ) : [ boolean, string, string ] => {
    const validation_rules : i_claim_rules[] = [
      { field : 'recipient_email' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
      { field :'recipient_email', validate : m_validate_email, error : lexique_message.email_error },
      { field :'recipient_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field :'recipient_name', validate : m_validate_str, error : lexique_message.special_error },
      { field :'insurance', validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field :'folder', validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field :'police', validate : m_validate_empty_fields, error : lexique_message.empty_error },
      { field :'template_id', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    ]
    const f_check_rows = ( body : i_claim_pdf ) : [ boolean , string , t_claim_fields] => {
        for (const validation of  validation_rules) {
            const { field, validate, error } = validation;
            if (!validate((body)[field])) return [false, error, field];
        }
        return [ true , '' , 'testing']
    }
    return f_check_rows(form);
  }
export { m_validate_login ,m_validate_customer_document, m_validate_claim, m_validate_uploads, m_validate_profil_information, m_validate_profil_passwords , m_validate_task , m_validate_planner , m_validate_user , m_validate_customer , m_validate_contacts , m_validate_customer_car , m_validate_recovery_reset , m_validate_app_config , m_validate_job}