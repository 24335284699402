

//Modules
import { Box } from "@mui/material";
import { useState , useEffect, useContext } from "react";

//Interfaces
import { i_initial_props , i_snack_alert , i_alert_props } from "../interfaces/utility.interface";
import { i_claim_errors, i_claim_inputs , i_claim_pdf, i_zoho_template  } from "../interfaces/customer.interface";
//Utils
import { f_fetch } from "../api/fetch";
import { CheckEnv } from "../utils/utility";
import { empty_promise } from "../utils/constant";

//Styles

//Components
import ClaimInputs from "../components/settings/claims/claim.inputs";
import AlertDialog from "../components/utility/alert";
import CircularUnderLoad from "../components/utility/center.loader";

//Middlewares
import { m_validate_claim } from "../validation/main.middleware";
import { m_validate_email , m_validate_empty_fields } from "../validation/utility.middleware";
import { MainContext } from "../context/context";
import { useNavigate } from "react-router-dom";

const ex_claim : any = {
    template_id : '',
    fields : {
        "Compagnie Assurance" : '',
        NDossier : '',
        NPolice : '',
        CAssureance2 : '',
        NDossier2 : '',
        NPolice2 : ''
    },
    recipient_email : '',
    recipient_name : '',
    testing: true
}

const default_claim : i_claim_pdf= {
    template_id : '',
    insurance : '',
    police : '',
    folder : '',
    recipient_email : '',
    recipient_name : '',
    testing: false
}

const errors_claim : i_claim_errors = {
    template_id : '',
    insurance : '',
    folder : '',
    recipient_email : '',
    recipient_name : '',
    police : '',
}

const Claim = ( props : i_initial_props ) => {
    const nav = useNavigate();
    const { HandleLogout } = useContext(MainContext)
    const [ form , setForm ] = useState<i_claim_pdf>(default_claim)
    const [ data , setData ] = useState<i_zoho_template[]>([])
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ errors , setErrors ] = useState<i_claim_errors>(errors_claim)
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});
    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true)
            const res = await f_fetch("/zoho/sign/templates" , 'GET' , true , null)
            if(res.type === 'Success'){
                setData(res.data)
                if(res.data.length > 0){
                    setForm({...form, template_id : res.data[0].template_id})
                }
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
        }
        fetchTemplates()
    }, [nav, HandleLogout])
    const handleSubmit = async() => {
        setLoading(true)
        const [ valid , err , field ] = await m_validate_claim( form )
        if(valid){
            const attentend_claim = {
                template_id : form.template_id,
                fields : {
                    "Compagnie Assurance" : form.insurance,
                    NDossier : form.folder,
                    NPolice : form.police,
                    CAssureance2 : form.insurance,
                    NDossier2 :form.folder,
                    NPolice2 : form.police
                },
                recipient_email : form.recipient_email,
                recipient_name : form.recipient_name,
                testing: form.testing
            }
            const res = await f_fetch("/zoho/sign/template" , 'POST' , true , attentend_claim)
            if(res.type === 'Success'){
                setForm({ ...default_claim , template_id : data[0].template_id })
                setApiError({ open : true, promise : res })
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setApiError({ open : true, promise : res })
            setLoading(false)
        }else{
            setErrors({...errors, [field] : err })
        }
    }
    useEffect(() => {
        const F = form
        const E = errors
        if(E.recipient_email !== '' && m_validate_email(F.recipient_email)) setErrors({ ...E , recipient_email : ''})
        if(E.recipient_name !== '' && m_validate_empty_fields(F.recipient_name)) setErrors({...E , recipient_name : ''})
        if(E.insurance!== '' && m_validate_empty_fields(F.insurance)) setErrors({...E , insurance : ''})
        if(E.folder!== '' && m_validate_empty_fields(F.folder)) setErrors({...E , folder : ''})
        if(E.police!== '' && m_validate_empty_fields(F.police)) setErrors({...E , police : ''})
    }, [form, errors])
    const claim_inputs_props : i_claim_inputs = {
        form : form,
        errors : errors,
        templates : data,
        setForm : setForm,
        setErrors : setErrors,
        loading : loading,
        callback : handleSubmit,
        dev : dev
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple'
    }
    return (
        <Box>
            { loading ? <CircularUnderLoad type={'full'}/> :
                <Box>
                    {data.length > 0 ?
                        <ClaimInputs {...claim_inputs_props} />
                        :
                        <Box sx={{ marginTop : 5 , textAlign : 'center' , fontSize : 22 , fontWeight : 800}}> Please add templates first via api or interface ( not implemented yet ). </Box>
                    }
                </Box>
            }
                <AlertDialog {...alert_props}/>
        </Box>
    )

}


export default Claim;