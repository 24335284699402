/*
 * Date: 2024
 * Description: Signin document page
 * Author: Nazim Kadouche @ skitsc
 */
import { Box, Button, Container, FormControl, Modal, Stack, TextField, Typography, } from "@mui/material";
import React, { ReactElement,  useEffect, useState } from "react";
import { Document, Page, Text, View, Image, StyleSheet, PDFViewer, } from "@react-pdf/renderer";
import { useSearchParams } from "react-router-dom";
import { f_fetch } from "../api/fetch";
import { i_customer_document } from "../interfaces/customer.interface";
import SignInput from "../components/utility/inputs/sign.inputs";
import { i_initial_props } from "../interfaces/utility.interface";
import { templates } from "../components/customers/modals/doc.modal";
import { empty_customer } from "../utils/constant";

// Styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#ffffff",
		padding: 20,
		display: "flex",
		flexDirection: "column",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
		gap: 10,
		justifyContent: "center",
	},
	title: {
		fontSize: 24,
		marginBottom: 10,
	},
	image: {
		width: 500,
		height: 300,
	},
});

interface i_doc_props {
	isEditing?: boolean;
	repair: i_customer_document | null;
	setSignature: (v: string) => void;
}

const Doc = ({ repair, isEditing, setSignature, }: i_doc_props): ReactElement => (
	<Document>
		<Page style={styles.page}>
			<View style={styles.section}>
				<Text style={styles.title}>Confirmation du choix du réparateur</Text>
				<Text>
					Je soussigné(e) {"fullName"}, confirme à mon assureur repair?.insurer
					avoir choisi Carrosserie Auto Prestige, située au 8680 rue Lafrenaie.
					Montréal (HIP 285), pour effectuer la réparation de mon véhicule.
				</Text>
				<Text>N° Dossier : repair?.dossier</Text>
				<Text>N° Police : repair?.police</Text>
			</View>
			<View style={styles.section}>
				<Text>Signature:</Text>
				{repair?.signature && (
					<Image src={repair?.signature || ""} style={{ height: "300px" }} />
				)}
				{isEditing && <SignInput onChange={setSignature} />}
			</View>
			<View style={styles.section}>
				<Text>
					Date: {new Date(repair?.timestamp || Date.now()).toLocaleDateString()}
				</Text>
			</View>
			<Text>Note : repair?.note</Text>
		</Page>
	</Document>
);
const Reports = (props: i_initial_props): ReactElement => {
	const [searchParams] = useSearchParams();
	const [otp, setOtp] = useState<string|undefined>(searchParams.get("pwd")?.toString());
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [err, setErr] = useState<string | null>(null);
	const [document, setDocument] = useState<i_customer_document | null>(null);
	const [signature, setSignature] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const fetch_document = async () => {
		if (isLoading) return;
			setIsLoading(true);
			let query = `?id=${searchParams.get("id")}&pwd=${searchParams.get("pwd")}`;
			const get_doc = await f_fetch(
				"/customer/documents" + query,
				"GET",
				false,
				null
			);
			if (get_doc.type === "Error") {
				setErr(get_doc.message);
			} else if (get_doc.type === "Success") {
				setErr(null);
				setDocument({ ...get_doc.data });
				if (isEditing !== !get_doc.data.signing_time) {
					setIsEditing(!get_doc.data.signing_time);
				}
			}
		setIsLoading(false);
	};

	useEffect(() => {
		console.log(searchParams.get("pwd"))
		setOtp(searchParams.get('pwd')?.toString())
		console.log(otp);

		if (otp) fetch_document();
		return () => {};
	}, [searchParams]);
	const submit = async () => {
		if (document?.signing_time || !otp) {
			return;
		}
		setIsLoading(true);
		const query = `?id=${searchParams.get("id")}&pwd=${otp}}`;
		const putRepair = await f_fetch( "/customer/" + document?.customer_id + "/documents" + query, "PUT", false, { ...document, signature, } );
		if (putRepair.type === "Error") {
			setErr(putRepair.message);
		} else {
			setErr(null);
			setIsEditing(false);
		}
		fetch_document();
	};
	const openSignModal = async () => {
		if (isModalOpen) return;
		setIsModalOpen(true);
	};
	const otpClick = () => {
		if (!otp || otp == "") return;
		if (searchParams.has("pwd")) searchParams.set("pwd", otp);
		else searchParams.append("pwd", otp);
		console.log("🚀 ~ otpClick ~ ", searchParams.get("pwd"))
		fetch_document();
	};
	if (err)
		return (
			<Container>
				<span>{err}</span>
			</Container>
		);
	if (!searchParams.get("pwd")?.toString()) {
		return (
			<Container sx={{ padding: 10 }}>
				<Typography fontWeight={600} marginBottom={3} fontSize={20}>
					Enter your code
				</Typography>
				<FormControl fullWidth>
					<TextField
						value={otp || ""}
						onChange={(e) => setOtp(e.target.value)}
						label="Code"
					/>
				</FormControl>
				<Button
					variant={"contained"}
					sx={{ marginTop: 5 }}
					fullWidth
					disabled={!otp || otp == ""}
					onClick={otpClick}
				>
					Sign
				</Button>
			</Container>
		);
	}
	if (isEditing)
		return (
			<Container style={{ width: 800 }}>
				{templates
					.find((el) => el.name == document?.title)
					?.el({
						customer: document?.customer || empty_customer,
						customer_cars: document?.cars || [],
						data: document?.data || {},
						signature: document?.signature,
					})}
				<Button
					onClick={openSignModal}
					disabled={isLoading}
					variant="contained"
					fullWidth
				>
					{isLoading ? "Signing..." : "Sign"}
				</Button>
				<Modal
					open={isModalOpen}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: "absolute" as "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							bgcolor: "background.paper",
							border: "2px solid #000",
							boxShadow: 24,
							p: 4,
						}}
					>
						<SignInput onChange={setSignature} />
						<Stack direction={"row"} gap={5} justifyContent={'flex-end'}>
							<Button variant="text" onClick={() => setIsModalOpen(false)}>
								Cancel
							</Button>
							<Button variant="contained" onClick={submit}>
								Submit
							</Button>
						</Stack>
					</Box>
				</Modal>
			</Container>
		);
	return (
		<PDFViewer style={{ height: "100vh", width: "100%" }}>
			{templates.find((el) => el.name == document?.title) ?.el({
					customer: document?.customer || empty_customer,
					customer_cars: document?.cars || [],
					data: document?.data || {},
					signature: document?.signature,
				})}
		</PDFViewer>
	);
};

export default Reports;
