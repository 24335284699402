

/*
 * Date: 2024
 * Description: Settings page being developped
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import React, { ReactElement , useContext } from "react";
import { Box , Grid, Typography , Paper , Button , TextField } from "@mui/material";
import { Link } from "react-router-dom";

//Components
import Footer from "../components/utility/footer";
//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface";

//Context
import { ThemeContext } from "../context/context";

const Settings = ( props : i_initial_props) : ReactElement => {
    const { mode } = useContext(ThemeContext)
    const main_boxes = {
        minHeight : '20vh' , width :'100%', borderRadius : '10px' , padding : '20px' , backgroundColor : mode === 'Light' ? 'lightgrey'  : '#111'
    }
    const grid_box = { minWidth : '250px'}
    const title_sx ={
        color : mode === 'Light' ? '#344054' : 'white' , textDecoration : 'initial' , fontWeight : '500'
    }
    const main_title_sx = { fontWeight : '600' , fontSize : '16px'}
    const line_sx = {
        marginTop : '1vh'
    }
    return (
        <Box>
            <Box sx={{ height: '91vh' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ display : 'flex' , borderBottom : '1px solid gray' , paddingTop : '15px', paddingBottom : '15px'}}>
                        <Box component={'img'} sx={{ marginRight : '10px' , marginLeft : '10px'}} src={'/images/settings.svg'}></Box>
                        <Typography sx={{ alignSelf : 'center' }}>
                            Settings
                        </Typography>
                        <TextField placeholder="Search by invoice number , date , customer" size="small" sx={{ height : '15px' , transform: 'translate(0, 1px) scale(0.75)' , marginTop : '-2px' , width : '400px'}}></TextField>
                        <Button variant="contained" sx={{ textTransform: 'none' , padding : '4px 10px' , marginLeft : '-35px'}} >Search</Button>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ marginLeft : '2%' , marginRight : '2%' , marginTop : '2vh'}}>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3}} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  >
                <Grid item xs={6} md={4} sm={5}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/batch_assign.svg'}></Box>
                            <Typography  sx={{ fontWeight : '600' , fontSize : '16px'}}>Customers</Typography>
                        </Box>
                        <Box sx={{}}>
                            <Grid container sx={line_sx}>
                                <Grid item xs={6}>
                                    <Link style={title_sx} to="/">Profile</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link style={title_sx} to="/">Manage Subscription</Link>
                                </Grid>
                            </Grid>
                            <Grid container sx={line_sx}>
                                <Grid item xs={6}>
                                    <Link style={title_sx} to="/">Customization</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link style={title_sx} to="/">Update Subscription</Link>
                                </Grid>
                            </Grid>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Customer domain</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Currency</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Approval</Link>
                            </Box>
                            <Grid container sx={line_sx}>
                                <Grid item xs={6}>
                                    <Link style={title_sx} to="/">Opening Balances</Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link style={{ ...title_sx , fontSize : 26}} to="/settings/claims">Customer claims</Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item  xs={4} sm={3} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/layers_three_red.svg'}></Box>
                            <Typography  sx={main_title_sx}>Jobs</Typography>
                        </Box>
                        <Box sx={{ paddingLeft : '20px'}}>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/bank_notes.svg'}></Box>
                            <Typography  sx={main_title_sx}>Invoicing</Typography>
                        </Box>
                        <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/dollar_account.svg'}></Box>
                            <Typography  sx={main_title_sx}>Expenses</Typography>
                        </Box>
                        <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/bank_account.svg'}></Box>
                            <Typography  sx={main_title_sx}>Banking</Typography>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Place holder</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Place holder</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Place holder</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Place holder</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Place holder</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Place holder</Link>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/payroll.svg'}></Box>
                            <Typography  sx={main_title_sx}>Payroll</Typography>
                        </Box>
                        <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/link.svg'}></Box>
                            <Typography  sx={main_title_sx}>Direct Links</Typography>
                        </Box>
                        <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                            <Box sx={line_sx}>
                                <Link style={title_sx} to="/">Place holder</Link>
                            </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4} md={2} sx={grid_box}>
                    <Paper elevation={6} sx={main_boxes}>
                        <Box sx={{ display : 'flex'}}>
                            <Box component={'img'} sx={{ marginRight : '10px'}} alt='Customer icon' src={'/images/settings.svg'}></Box>
                            <Typography sx={main_title_sx}>Setup Application</Typography>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/settings/users">Manage users</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/settings/app.config">Configure</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Export data</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Logs</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">About</Link>
                        </Box>
                        <Box sx={line_sx}>
                            <Link style={title_sx} to="/">Version</Link>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            </Box>
            </Box>
            <Footer type={'center'}/>
        </Box>
    );
}


export default Settings
